<form [formGroup]="surroundForm" class="d-flex">
  <div class="dropdown">
      <button
        [disabled]="disabled"
        (click)="toggleSurrounding()"
        type="button"
        class="radius-btn btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        id="range"
      >
        <fa-icon [icon]="faGlobeEurope" size="lg" title="globe"></fa-icon>
        <input
          #surroundInput
          [disabled]="disabled"
          formcontrolName="surroundings"
          class="surrounding-input"
          aria-label="surrounding"
          value="100"
        />km
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu" #surroundDropdown aria-labelledby="range">
        <button class="dropdown-item" (click)="changeSurrounding(10)">10km</button>
        <button class="dropdown-item" (click)="changeSurrounding(50)">50km</button>
        <button class="dropdown-item" (click)="changeSurrounding(100)">100km</button>
        <button class="dropdown-item" (click)="changeSurrounding(250)">250km</button>
      </div>
    </div>
  <div class="dropdown flex-grow-1">
    <input
      formControlName="city"
      [disabled]="disabled"
      [readOnly]="disabled"
      placeholder="{{ messages.filterDropdownCity }}"
      type="text"
      class="w-100 form-control city-input"
      aria-label="Ort"
    />
    <div class="dropdown-menu" #suggestCityDropdown>
      <div
        class="dropdown-item"
        *ngFor="let city of cityAutosuggest"
        (click)="setCity(city.name, city.id)"
        (keydown.enter)="setCity(city.name, city.id)"
      >
        {{ city.name }}
      </div>
    </div>
    <div class="dropdown-menu" #suggestCityError>
      <div class="dropdown-item">
        <div class="text-warning">Ort nicht gefunden</div>
      </div>
    </div>
  </div>
</form>
