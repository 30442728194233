import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faUndo } from '@fortawesome/free-solid-svg-icons';
import {
  NgbCalendar,
  NgbDate,
  NgbDropdownModule,
  NgbInputDatepicker,
  NgbNavChangeEvent,
  NgbNavConfig,
  NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../config.service';
import { PathEnum } from '../../../enums/path-enum';
import { MessageService } from '../../../message-service';
import { MessageJson } from '../../../models/message-json';
import { ProfessionalScopesJson } from '../../../models/professional-scope-json';
import { SearchRequestJson } from '../../../models/search-request-json';
import { SurroundingComponent } from '../../../page-elements/surrounding/surrounding.component';
import { SearchService } from '../../../search/search/search.service';
import { UtilsService } from '../../../services/utils.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RequestSpinnerDirective } from '../../../directives/request-spinner.directive';
import { ActivetoggleDirective } from '../../../directives/activetoggle.directive';

@Component({
  standalone: true,
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  imports: [
    CommonModule,
    NgbNavModule,
    NgOptimizedImage,
    NgbDropdownModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbInputDatepicker,
    RequestSpinnerDirective,
    SurroundingComponent,
    ActivetoggleDirective
  ],
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, AfterViewInit {
  faUndo = faUndo;
  faCalendarAlt = faCalendarAlt;
  faSearch = faSearch;

  @ViewChildren('catItemElements')
  catItemElements: QueryList<ElementRef>;

  @ViewChild('furtherCategories', { static: true })
  furtherCategories: ElementRef;
  @ViewChild('viewCategory', { read: ViewContainerRef })
  viewCategory: ViewContainerRef;
  @ViewChild('viewFilter', { read: ViewContainerRef })
  viewFilter: ViewContainerRef;

  @ViewChild(SurroundingComponent)
  surrounding: SurroundingComponent;

  messages: MessageJson;
  topCategories: Array<ProfessionalScopesJson> = [];
  furtherCathegories: Array<ProfessionalScopesJson> = [];
  furtherCategoryText: string;
  furtherCategoryTextDefault: string;
  lastSearch: SearchRequestJson;
  isFurtherCategory = false;
  topCategoriesIds = [];

  selectedScopeId: number;
  selectedScopeName: string = null;
  searchReqValues: SearchRequestJson =
    SearchService.getSearchDefaultParameter();

  searchForm = new UntypedFormGroup({
    scope: new UntypedFormControl(null, Validators.required),
    radio: new UntypedFormControl(null, Validators.required),
    courseType: new UntypedFormControl(null, Validators.required),
    dateFrom: new UntypedFormControl(null, Validators.required),
    dateTo: new UntypedFormControl()
  });
  PathEnum;

  constructor(
    private router: Router,
    private calendar: NgbCalendar,
    private renderer: Renderer2,
    private messageService: MessageService,
    public config: ConfigService,
    navConfig: NgbNavConfig
  ) {
    navConfig.roles = false;
  }

  ngOnInit(): void {
    this.messages = this.messageService.getMessages();
    this.PathEnum = PathEnum;
    this.topCategories = this.messages.startpageScopes;
    for (const cat of this.topCategories) {
      this.topCategoriesIds.push(cat.id);
    }
    this.furtherCathegories = this.messages.startpageFurtherscope;
    this.furtherCategoryText = this.messages.filterDropdownFurtherScopes;
    this.furtherCategoryTextDefault = this.furtherCategoryText;
    this.lastSearch = SearchService.getSearchParameter();
    this.searchForm.controls.courseType.setValue('ALL');
    if (!this.lastSearch) {
      this.searchForm.controls.radio.setValue(
        this.config.getDefaultSearchType()
      );
      this.searchForm.controls.dateFrom.setValue(this.calendar.getToday());
    } else {
      this.searchForm.controls.radio.setValue(this.lastSearch.types);
      if (this.lastSearch.courseType) {
        if (this.lastSearch.courseType instanceof Array) {
          this.searchForm.controls.courseType.setValue(
            this.lastSearch.courseType[0]
          );
        } else {
          this.searchForm.controls.courseType.setValue(
            this.lastSearch.courseType
          );
        }
      }
      const from = UtilsService.toDate(this.lastSearch.dateFrom);
      if (from) {
        this.searchForm.controls.dateFrom.setValue(
          UtilsService.toDate(this.lastSearch.dateFrom)
        );
      } else {
        this.searchForm.controls.dateFrom.setValue(this.calendar.getToday());
      }
      this.searchForm.controls.dateTo.setValue(
        UtilsService.toDate(this.lastSearch.dateTo)
      );
    }
    this.searchForm.setValidators(this.getFormValidator());
  }

  ngAfterViewInit(): void {
    if (this.lastSearch) {
      if (this.topCategoriesIds.includes(this.lastSearch.scope)) {
        this.setTopCat(
          this.lastSearch.scope,
          this.lastSearch.selectedScopeName
        );
      } else {
        this.selectFurtherCat(this.lastSearch.scope);
      }
      this.selectedScopeId = this.lastSearch.scope;
      if (this.surrounding) {
        this.surrounding.setCity(
          this.lastSearch.cityName,
          this.lastSearch.cityId
        );
        if (this.lastSearch.radius) {
          this.surrounding.changeSurrounding(this.lastSearch.radius);
          this.surrounding.closeSurrounding();
        }
      }
    }
  }

  private getFormValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const from = NgbDate.from(group.controls['dateFrom'].value);
      const to = NgbDate.from(group.controls['dateTo'].value);
      if (from.before(this.calendar.getToday())) {
        group.controls['dateFrom'].setErrors({ notInFuture: true });
      } else {
        group.controls['dateFrom'].setErrors(null);
      }
      if (to != null && (from.equals(to) || from.after(to))) {
        group.controls['dateTo'].setErrors({ endBeforeStart: true });
      } else {
        group.controls['dateTo'].setErrors(null);
      }
      return;
    };
  }

  resetFurtherCat() {
    this.furtherCategoryText = this.furtherCategoryTextDefault;
  }

  removeCatSelection() {
    this.searchForm.controls.scope.patchValue(null);
    this.selectedScopeName = null;
    const active =
      this.viewCategory.element.nativeElement.querySelectorAll('a.activated');
    if (active.length > 0) {
      this.renderer.removeClass(active[0], 'activated');
      this.resetFurtherCat();
    }
  }

  showCategoryAndHideFilterView() {
    this.renderer.removeClass(
      this.viewCategory.element.nativeElement,
      'd-none'
    );
    this.renderer.removeClass(this.viewFilter.element.nativeElement, 'd-block');
    this.renderer.addClass(this.viewFilter.element.nativeElement, 'd-none');
    this.renderer.addClass(this.viewCategory.element.nativeElement, 'd-block');
    this.removeCatSelection();
  }

  setSelectedCat(catId, catName) {
    this.searchForm.controls.scope.patchValue(catId);
    this.selectedScopeName = catName;
    this.showFilterAndHideCategoryView();
  }

  setTopCat(catId, catName, $event?: KeyboardEvent) {
    if ($event && $event.code !== 'Enter') {
      return;
    }
    this.setSelectedCat(catId, catName);
    this.resetFurtherCat();
  }

  selectFurtherCat(catId, $event?: KeyboardEvent) {
    if ($event && $event.code !== 'Enter') {
      return;
    }
    this.isFurtherCategory = true;
    for (const scope of this.furtherCathegories) {
      if (scope.id === catId) {
        this.setSelectedCat(catId, scope.name);
        this.furtherCategoryText = scope.name;
        break;
      }
    }
  }

  showFilterAndHideCategoryView() {
    this.renderer.removeClass(this.viewFilter.element.nativeElement, 'd-none');
    this.renderer.removeClass(
      this.viewCategory.element.nativeElement,
      'd-block'
    );
    this.renderer.addClass(this.viewCategory.element.nativeElement, 'd-none');
    this.renderer.addClass(this.viewFilter.element.nativeElement, 'd-block');
  }

  onSearchSubmit() {
    this.searchReqValues.scope = this.searchForm.controls.scope.value;
    this.searchReqValues.selectedScopeName = this.selectedScopeName;
    this.searchReqValues.types = this.searchForm.controls.radio.value;
    if (this.searchForm.controls.courseType.value !== 'ALL') {
      this.searchReqValues.courseType =
        this.searchForm.controls.courseType.value;
    }

    if (
      this.searchForm.controls.courseType.value === 'ALL' ||
      this.searchForm.controls.courseType.value === 'PRESENT'
    ) {
      if (this.surrounding) {
        const surroundingValues = this.surrounding.getValues();
        this.searchReqValues.cityId = surroundingValues.cityId;
        this.searchReqValues.cityName = surroundingValues.cityName;
        this.searchReqValues.radius = surroundingValues.radius;
      }
    }

    if (this.searchForm.controls.courseType.value !== 'SELF') {
      this.searchReqValues.dateFrom = UtilsService.toRequestFormat(
        this.searchForm.controls.dateFrom.value
      );
      this.searchReqValues.dateTo = null;

      if (this.searchForm.controls.dateTo.value) {
        this.searchReqValues.dateTo = UtilsService.toRequestFormat(
          this.searchForm.controls.dateTo.value
        );
      }
    }

    SearchService.initSearchParameter(this.searchReqValues);
    this.router.navigate([PathEnum.search], {
      queryParams: this.searchReqValues,
      queryParamsHandling: 'merge'
    });
  }

  resetFilter() {
    this.showCategoryAndHideFilterView();
    this.searchForm.controls.radio.setValue('EDUCATION');
    this.searchForm.controls.dateFrom.setValue(this.calendar.getToday());
    this.searchForm.controls.dateTo.setValue(null);
    this.surrounding.setCity(null, null);
    this.surrounding.changeSurrounding(100);
    this.surrounding.closeSurrounding();
    this.resetEducationFilter();
  }

  resetEducationFilter() {
    this.searchForm.controls.courseType.setValue('ALL');
  }

  showCityFilter() {
    return (
      this.searchForm.controls.radio.value === 'SPECIALIZATION' ||
      this.searchForm.controls.courseType.value === 'ALL' ||
      this.searchForm.controls.courseType.value === 'PRESENT'
    );
  }

  showTimeFilter() {
    return (
      this.searchForm.controls.radio.value === 'SPECIALIZATION' ||
      this.searchForm.controls.courseType.value !== 'SELF'
    );
  }

  showTypeSelection() {
    return Object.keys(this.config.getTypes()).length > 1;
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    changeEvent.preventDefault();
  }

  getTypes() {
    const types = new Map(Object.entries(this.config.getTypes()));
    if (types.has('EDUCATION')) {
      types.delete('RENO');
    }
    return types;
  }
}
