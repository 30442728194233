import { AggregatedRatingJsonLd } from './aggregated-rating-json-ld';
import {JsonLd} from "./json-ld";

export class OrganizationJsonLd implements JsonLd {
  readonly '@context' = 'https://schema.org';
  readonly '@type' = 'Corporation';
  name: string;
  url: string;
  logo: string;
  sameAs: Array<string>;
  aggregateRating: AggregatedRatingJsonLd;

  [param: string]: string | Object;
}
