import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { ConfigService } from '../../config.service';
import { MessageService } from '../../message-service';
import { MessageJson } from '../../models/message-json';
import { NewsletterRequest } from '../../models/newsletter-request';
import { Api } from '../../services/api.service';

@Component({
  standalone: true,
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  imports: [CommonModule, FormsModule, FontAwesomeModule, RouterModule]
})
export class NewsletterComponent implements OnInit {
  faEnvelope = faEnvelope;

  style = 'dark';

  send = false;
  messages: MessageJson;
  validationErrors: Map<string, string> = new Map<string, string>();
  data: NewsletterRequest = {
    email: null
  };

  constructor(
    private api: Api,
    private messageService: MessageService,
    private configService: ConfigService,
    private router: Router
  ) {
    this.router.events.subscribe(() => this.resetStatus());
  }

  private resetStatus() {
    this.send = false;
    this.data.email = null;
  }

  ngOnInit() {
    this.messages = this.messageService.getMessages();
  }

  name() {
    return this.configService.config.name;
  }

  formSubmit() {
    if (this.data.email) {
      this.api.registerNewsletter(this.data).subscribe(
        (reqErg) => {
          this.send = true;
        },
        (error) => {
          console.debug('handling error');
          const errorResponse: HttpErrorResponse = error;
          if (errorResponse.status === 422) {
            this.validationErrors.set(
              'email',
              'Bitte geben Sie eine gültige E-Mail-Adresse an!'
            );
          } else {
            this.validationErrors.set('unexpected error', 'unexpected error');
            console.debug(errorResponse.message);
          }
        }
      );
    }
  }
}
