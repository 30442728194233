import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../config.service';
import { MessageService } from '../../message-service';
import { AnswerJsonLd } from '../../models/AnswerJsonLd';
import { FaqJsonLd } from '../../models/FaqJsonLd';
import { MessageJson } from '../../models/message-json';
import { QuestionJsonLd } from '../../models/QuestionJsonLd';
import { MetaService } from '../../services/meta-service';
import { JsonLd } from '../../models/json-ld';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { RouteTransformerDirective } from '../directives/route-transformer';
import { SafeHtmlPipe } from '../../directives/safe-html-pipe';

@Component({
  standalone: true,
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  imports: [
    CommonModule,
    NgbAccordionModule,
    RouteTransformerDirective,
    SafeHtmlPipe
  ]
})
export class FaqComponent implements OnInit {
  schemas: Array<JsonLd> = [];

  @Input()
  faq = new Array<any>();
  faqMap = new Map<String, any>();
  messages: MessageJson;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private messageService: MessageService,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.messages = this.messageService.getMessages();
    let lastCategory = '';
    let faqList = [];
    for (const question of this.faq) {
      if (lastCategory !== question.category) {
        lastCategory = question.category;
        faqList = [];
        this.faqMap.set(lastCategory, faqList);
      }
      faqList.push(question);
    }
    this.createJsonFaq(this.faq);
  }

  private createJsonFaq(faq: any[]) {
    const questions: QuestionJsonLd[] = [];
    for (const question of faq) {
      const jsonld = new QuestionJsonLd();
      jsonld.name = question.question;
      const answer = new AnswerJsonLd();
      answer.text = question.answer;
      jsonld.acceptedAnswer = answer;
      questions.push(jsonld);
    }
    const faqLd = new FaqJsonLd();
    faqLd.mainEntity = questions;
    this.schemas.push(faqLd);
    this.metaService.setJsonLd(this.schemas);
  }

  asIsOrder(a, b) {
    return 1;
  }
}
