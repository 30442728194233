import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  subscription: Subscription;
  affiliateId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {}

  initAffiliateConfig() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const tmpId = this.route.snapshot.queryParamMap.get('aid');
        if (tmpId) {
          this.affiliateId = tmpId;
        }
        if (this.affiliateId) {
          const optanonConsent = this.cookieService.get('OptanonConsent');
          const optanonClosed = this.cookieService.get('OptanonAlertBoxClosed');
          if (optanonClosed && optanonConsent) {
            this.setAffiliateCookie(optanonConsent);
          } else {
            this.createCookiePoll();
          }
        }
      });
  }

  private setAffiliateCookie(optanonConsent: string) {
    if (
      optanonConsent.includes('C0002:1') &&
      this.configService.config.lifetime !== undefined
    ) {
      this.cookieService.set(
        'aid',
        this.affiliateId,
        this.configService.config.lifetime,
        '/',
        document.location.hostname,
        true,
        'Strict'
      );
    }
  }

  private createCookiePoll() {
    const source = interval(10000);
    this.subscription = source.subscribe((val) => {
      const optanonConsent = this.cookieService.get('OptanonConsent');
      const optanonClosed = this.cookieService.get('OptanonAlertBoxClosed');
      if (optanonClosed && optanonConsent) {
        this.setAffiliateCookie(optanonConsent);
        this.subscription.unsubscribe();
      }
    });
  }

  getAffiliate() {
    let aid = this.cookieService.get('aid');
    if (!aid) {
      aid = this.affiliateId;
    }
    return aid;
  }
}
