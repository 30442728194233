import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { CourseResponseJson } from '../models/course-response-json';
import { CustomerPackage } from '../models/customer-package';
import { CoursePackage, ProfessionalScope } from '../../../build/course-api';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  constructor(private configurationService: ConfigService) {}

  public getMatchingPackages(course: CourseResponseJson): CustomerPackage[] {
    const cp = localStorage.getItem('cp');
    if (!cp || !this.configurationService.isFeatureActive('packagesEnabled')) {
      return null;
    }
    const customerPackages = JSON.parse(cp) as CustomerPackage[];
    return customerPackages
      .filter((p) => p.organizerId === course.organizer.id)
      .filter(
        (p) =>
          p.scopeIds.length === 0 ||
          p.scopeIds.some((id) =>
            course.professionalScopes.map((s) => s.id).includes(id)
          )
      );
  }

  handleAddedPackage(packageData: CoursePackage) {
    const cp = localStorage.getItem('cp');
    let packages = JSON.parse(cp) as CustomerPackage[];
    if (!packages) {
      packages = [];
    }
    if (!packages.some((value) => value.packageId === packageData.id)) {
      packages.push({
        type: packageData.educationalType,
        organizerId: packageData.organizer.id,
        scopeIds: packageData.professionalScopeIds,
        remainingCredits: packageData.creditValue,
        cartPosition: true,
        packageId: packageData.id
      } as CustomerPackage);
      localStorage.setItem('cp', JSON.stringify(packages));
    }
  }

  handleAddedCourse(
    courseScopes: ProfessionalScope[],
    organizerId: number,
    duration: number
  ) {
    let credits = duration;
    const cp = localStorage.getItem('cp');
    const packages = JSON.parse(cp) as CustomerPackage[];

    for (const p of packages) {
      if (
        p.organizerId === organizerId &&
        (p.scopeIds.length === 0 ||
          p.scopeIds.some((id) => courseScopes.map((s) => s.id).includes(id)))
      ) {
        if (p.remainingCredits > credits) {
          p.remainingCredits = p.remainingCredits - credits;
        } else {
          credits = credits - p.remainingCredits;
          p.remainingCredits = 0;
        }
      }
    }
    localStorage.setItem('cp', JSON.stringify(packages));
  }

  handleRemovedCourse(
    packageIncluded: boolean,
    duration: number,
    courseId: number,
    packageId: number,
    type: string,
    organizerId: number,
    scopeIds: number[]
  ) {
    const cp = localStorage.getItem('cp');
    const packages = JSON.parse(cp) as CustomerPackage[];
    if (packageIncluded) {
      let credits = duration;

      if (courseId) {
        for (const p of packages) {
          if (
            p.organizerId === organizerId &&
            (p.scopeIds.length === 0 ||
              p.scopeIds.some((id) => scopeIds.includes(id))) &&
            p.type === type
          ) {
            p.remainingCredits = p.remainingCredits + credits;
          }
        }
      } else if (packageId) {
        const index = packages.findIndex(
          (value) => value.packageId === packageId
        );
        packages.splice(index, 1);
      }
      localStorage.setItem('cp', JSON.stringify(packages));
    } else if (packageId) {
      const index = packages.findIndex(
        (value) => value.packageId === packageId
      );
      packages.splice(index, 1);
    }
    localStorage.setItem('cp', JSON.stringify(packages));
  }
}
