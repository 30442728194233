import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../message-service';
import { MessageJson } from '../../models/message-json';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-advert',
  templateUrl: './advert.component.html',
  imports: [CommonModule],
  styleUrls: ['./advert.component.scss']
})
export class AdvertComponent implements OnInit {
  messages: MessageJson;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    this.messages = this.messageService.getMessages();
  }
}
