import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  standalone: true,
  selector: '[appRouteTransformer]'
})
export class RouteTransformerDirective {
  constructor(
    private el: ElementRef,
    private router: Router
  ) {}

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      const destination = event.target.getAttribute('href');
      if (
        destination.toString().startsWith('mailto:') ||
        destination.toString().startsWith('http') ||
        destination.toString().indexOf('#') > 0
      ) {
        return;
      }
      this.router.navigate([destination]);
      event.preventDefault();
    } else {
      return;
    }
  }
}
