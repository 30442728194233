import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { SiteModule } from '../configuration/site-module';
import { ScopesComponent } from '../pages/start/scopes/scopes.component';
import { FaqComponent } from '../static-content/faq/faq.component';

@Injectable({
  providedIn: 'root'
})
export class SiteModuleLoaderService {
  constructor() {}

  loadComponent(vcr: ViewContainerRef, modules: SiteModule[]) {
    vcr.clear();
    const loadingComponsents = new Array<Promise<any>>();
    for (const module of modules) {
      switch (module.name) {
        case 'ORGANIZER_INFO':
          loadingComponsents.push(
            import('../pages/start/organizer-info/organizer-info.component')
          );
          break;
        case 'NEWS':
          loadingComponsents.push(import('../pages/start/news/news.component'));
          break;
        case 'BENEFITS_BOX':
          loadingComponsents.push(
            import('../page-elements/benefits/benefits.component')
          );
          break;
        case 'BENEFITS_BOX_IMMO_CAMPUS':
          loadingComponsents.push(
            import(
              '../page-elements/benefits-immo-campus/benefits-immo-campus.component'
            )
          );
          break;
        case 'TESTIMONIAL':
          loadingComponsents.push(
            import('../pages/start/testimonial/testimonial.component')
          );
          break;
        case 'WHO_TO':
          loadingComponsents.push(import('../pages/start/flow/flow.component'));
          break;
        case 'ABOUT_US':
          loadingComponsents.push(
            import('../page-elements/about-rf/about-rf.component')
          );
          break;
        case 'CATEGORIES':
          loadingComponsents.push(
            import('../page-elements/category-view/category-view.component')
          );
          break;
        case 'SCOPE_OVERVIEW':
          loadingComponsents.push(
            import('../pages/start/scopes/scopes.component')
          );
          break;
        case 'SCOPE_OVERVIEW_RENO':
          loadingComponsents.push(
            import('../pages/start/scopes/scopes.component')
          );
          break;
        case 'NEWSLETTER':
          loadingComponsents.push(
            import('../pages/start/newsletter-box/newsletter-box.component')
          );
          break;
        case 'JUFA_START':
          loadingComponsents.push(
            import('../jfs/jufa-start/jufa-start.component')
          );
          break;
        case 'COURSE_PREVIEW':
          loadingComponsents.push(
            import(
              '../page-elements/course-preview/preview-box/preview-box.component'
            )
          );
          break;
        case 'WELCOME':
          loadingComponsents.push(
            import('../page-elements/welcome/welcome.component')
          );
          break;
        case 'TEXT_SEARCH':
          loadingComponsents.push(
            import('../page-elements/text-search/text-search.component')
          );
          break;
        case 'EKBW_COURSE_OVERVIEW':
          loadingComponsents.push(
            import('../ekbw/scope-overview/scope-overview.component')
          );
          break;
      }
    }
    Promise.all(loadingComponsents).then((t) => {
      for (let i = 0; i < modules.length; i++) {
        switch (modules[i].name) {
          case 'ORGANIZER_INFO':
            this.loadInput(t[i].OrganizerInfoComponent, vcr);
            break;
          case 'NEWS':
            this.loadInput(t[i].NewsComponent, vcr);
            break;
          case 'BENEFITS_BOX':
            this.loadInput(t[i].BenefitsComponent, vcr);
            break;
          case 'BENEFITS_BOX_IMMO_CAMPUS':
            this.loadInput(t[i].BenefitsImmoCampusComponent, vcr);
            break;
          case 'TESTIMONIAL':
            this.loadInput(t[i].TestimonialComponent, vcr);
            break;
          case 'WHO_TO':
            this.loadInput(t[i].FlowComponent, vcr);
            break;
          case 'ABOUT_US':
            this.loadInput(t[i].AboutRfComponent, vcr);
            break;
          case 'CATEGORIES':
            this.loadInput(t[i].CategoryViewComponent, vcr);
            break;
          case 'SCOPE_OVERVIEW':
            this.loadScopeView(t[i].ScopesComponent, vcr, 'FAO');
            break;
          case 'SCOPE_OVERVIEW_RENO':
            this.loadScopeView(t[i].ScopesComponent, vcr, 'RENO');
            break;
          case 'NEWSLETTER':
            this.loadInput(t[i].NewsletterBoxComponent, vcr);
            break;
          case 'JUFA_START':
            this.loadInput(t[i].JufaStartComponent, vcr);
            break;
          case 'COURSE_PREVIEW':
            this.loadInput(t[i].PreviewBoxComponent, vcr);
            break;
          case 'WELCOME':
            this.loadInput(t[i].WelcomeComponent, vcr);
            break;
          case 'TEXT_SEARCH':
            this.loadInput(t[i].TextSearchComponent, vcr);
            break;
          case 'EKBW_COURSE_OVERVIEW':
            this.loadInput(t[i].ScopeOverviewComponent, vcr);
            break;
        }
      }
    });
  }

  private loadScopeView(
    component: Type<ScopesComponent>,
    viewContainerRef: ViewContainerRef,
    config: string
  ) {
    const componentRef = viewContainerRef.createComponent(component);
    if (config) {
      componentRef.instance.config = config;
    }
  }

  loadFAQ(viewContainerRef: ViewContainerRef, faq: any[]) {
    const componentRef = viewContainerRef.createComponent(FaqComponent);
    componentRef.instance.faq = faq;
  }

  loadInput(component: any, viewContainerRef: ViewContainerRef) {
    viewContainerRef.createComponent(component);
  }

  loadScopeOverview(viewContainerRef: ViewContainerRef) {
    viewContainerRef.clear();
    import('../pages/start/scopes/scopes.component').then((module) => {
      const component = viewContainerRef.createComponent(
        module.ScopesComponent
      );
      component.instance.cityPage = true;
    });
  }

  loadSearchResultView(
    viewContainerRef: ViewContainerRef,
    defaultView: boolean,
    selectedScopeName?: string
  ) {
    if (defaultView) {
      viewContainerRef.clear();
      import('../page-elements/course-list/course-list.component').then(
        (module) => {
          const component = viewContainerRef.createComponent(
            module.CourseListComponent
          );
          if (selectedScopeName) {
            component.instance.selectedScopeName = selectedScopeName;
          }
        }
      );
    } else {
      viewContainerRef.clear();
      import('../jfs/jufa-course-list/jufa-course-list.component').then(
        (module) => {
          const component = viewContainerRef.createComponent(
            module.JufaCourseListComponent
          );
          if (selectedScopeName) {
            component.instance.selectedScopeName = selectedScopeName;
          }
        }
      );
    }
  }

  loadCourseDetails(viewContainerRef: ViewContainerRef, defaultView: boolean) {
    if (defaultView) {
      viewContainerRef.clear();
      import('../course/course-detail/course-detail.component').then(
        (module) => {
          viewContainerRef.createComponent(module.CourseDetailComponent);
        }
      );
    } else {
      viewContainerRef.clear();
      import('../jfs/jfs-course-detail/jfs-course-detail.component').then(
        (module) => {
          viewContainerRef.createComponent(module.JfsCourseDetailComponent);
        }
      );
    }
  }

  loadHeaderView(viewContainerRef: ViewContainerRef, featureActive: boolean) {
    if (featureActive) {
      viewContainerRef.clear();
      import('../jfs/page-header/page-header.component').then((module) => {
        viewContainerRef.createComponent(module.PageHeaderComponent);
      });
    }
  }
}
