import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from '../../message-service';
import { MessageJson } from '../../models/message-json';
import { SuggestCityJson } from '../../models/suggest-city-json';
import { Api } from '../../services/api.service';

@Component({
  standalone: true,
  selector: 'app-surrounding',
  templateUrl: './surrounding.component.html',
  styleUrls: ['./surrounding.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule]
})
export class SurroundingComponent implements OnInit {
  faGlobeEurope = faGlobeEurope;

  messages: MessageJson;

  @ViewChild('surroundInput', { static: true })
  surroundInput: ElementRef;
  @ViewChild('surroundDropdown', { static: true })
  surroundDropdown: ElementRef;
  @ViewChild('suggestCityDropdown', { static: true })
  suggestCityDropdown: ElementRef;
  @ViewChild('suggestCityError', { static: true })
  suggestCityError: ElementRef;

  @Input() disabled = false;

  @Output() valueChange = new EventEmitter();

  cityAutosuggest: Array<SuggestCityJson>;
  cityReqValues;
  citySuggestionClicked = false;

  surroundForm = new UntypedFormGroup({
    city: new UntypedFormControl(),
    cityId: new UntypedFormControl(),
    surroundings: new UntypedFormControl()
  });

  constructor(
    private messageService: MessageService,
    private api: Api,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.messages = this.messageService.getMessages();
    this.surroundForm.controls.city.valueChanges
      .pipe(debounceTime(300))
      .subscribe((val) => this.citySuggest(val));
  }

  init(city: string, cityId: number, radius: number) {
    if (city != null) {
      this.surroundForm.controls.city.setValue(city);
    }
    if (cityId != null) {
      this.surroundForm.controls.cityId.setValue(cityId);
    }
    if (radius != null) {
      this.surroundInput.nativeElement.value = radius;
    }
  }

  citySuggest(value: string) {
    if (this.citySuggestionClicked) {
      // prevent useless request
      this.citySuggestionClicked = false;
    } else {
      if (value.length === 0) {
        this.valueChange.emit('surrounding-changed');
      } else if (value.length <= 2) {
        this.cityAutosuggest = null;
        this.surroundForm.controls.cityId.setValue(null);
        this.renderer.removeClass(
          this.suggestCityDropdown.nativeElement,
          'show'
        );
        this.renderer.removeClass(this.suggestCityError.nativeElement, 'show');
      } else if (value.length > 2) {
        this.cityReqValues = {
          input: value
        };
        this.api.getSuggestCity(this.cityReqValues).subscribe((result) => {
          this.cityAutosuggest = result;
          if (result != null && result.length > 0) {
            this.renderer.addClass(
              this.suggestCityDropdown.nativeElement,
              'show'
            );
            this.renderer.removeClass(
              this.suggestCityError.nativeElement,
              'show'
            );
          } else {
            this.surroundForm.controls.cityId.setValue(null);
            this.setCity(value, null);
            this.renderer.removeClass(
              this.suggestCityDropdown.nativeElement,
              'show'
            );
            this.renderer.addClass(this.suggestCityError.nativeElement, 'show');
          }
        });
      }
    }
  }

  setCity(name: string, id: number) {
    this.cityAutosuggest = null;
    this.citySuggestionClicked = true;
    this.renderer.removeClass(this.suggestCityDropdown.nativeElement, 'show');
    this.surroundForm.controls.city.setValue(name);
    this.surroundForm.controls.cityId.setValue(id);
    this.valueChange.emit('surrounding-changed');
  }

  changeSurrounding(newValue) {
    this.surroundInput.nativeElement.value = newValue;
    this.toggleSurrounding();
    this.valueChange.emit('surrounding-changed');
  }

  toggleSurrounding() {
    if (this.surroundDropdown.nativeElement.classList.contains('show')) {
      this.closeSurrounding();
    } else {
      this.renderer.addClass(this.surroundDropdown.nativeElement, 'show');
    }
  }

  closeSurrounding() {
    this.renderer.removeClass(this.surroundDropdown.nativeElement, 'show');
  }

  getValues() {
    let radiusValue = null;
    let cityIdValue = null;
    if (this.surroundForm.controls.city.value) {
      radiusValue = this.surroundInput.nativeElement.value;
      cityIdValue = this.surroundForm.controls.cityId.value;
    }

    return {
      cityId: cityIdValue,
      cityName: this.surroundForm.controls.city.value || null,
      radius: radiusValue
    };
  }

  reset() {
    this.setCity(null, null);
    this.renderer.removeClass(this.suggestCityError.nativeElement, 'show');
  }
}
