<div class="full row align-content-center">
  <div class="col-2"></div>
  <div class="col-6">
    <div class="row error-code font-italic mb-2">404</div>
    <div class="row error-message">Upps! Da ist was schief gelaufen.</div>
    <div class="row error-message">
      Die gesuchte Seite existiert leider nicht!
    </div>
    <div class="row error-link">
      Aber kein Problem! Versuchen Sie es einfach nochmal!
    </div>
    <div class="row mt-2">
      <app-external-link [externalLink]="messages.mainDomain" [internalRoute]="'/'">
        <ng-container>zur Startseite</ng-container>
      </app-external-link>
    </div>
  </div>
</div>
