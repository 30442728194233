import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ConfigService } from '../../config.service';
import { SiteModuleLoaderService } from '../../services/site-module-loader.service';
import { SearchBoxComponent } from './search-box/search-box.component';
import { AdvertComponent } from '../../page-elements/advert/advert.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-start',
  templateUrl: './start.component.html',
  imports: [CommonModule, SearchBoxComponent, AdvertComponent],
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  @ViewChild('moduleHost', { read: ViewContainerRef, static: true })
  moduleHost: ViewContainerRef;

  constructor(
    private modulLoader: SiteModuleLoaderService,
    public config: ConfigService
  ) {}

  ngOnInit() {
    const viewContainerRef = this.moduleHost;
    this.modulLoader.loadComponent(
      viewContainerRef,
      this.config.getStartpageModules()
    );
  }
}
