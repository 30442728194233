import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgIf, NgTemplateOutlet} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  imports: [RouterLink, NgIf, NgTemplateOutlet],
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent {
  @Input()
  externalLink: string
  @Input()
  internalRoute: string;
}
