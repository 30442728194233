import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { ConfigService } from './config.service';
import { SiteConfiguration } from './configuration/site-configuration';
import { MessageJson } from './models/message-json';
import { ProfessionalScopesJson } from './models/professional-scope-json';
import { AffiliateService } from './services/affiliate.service';
import { Api } from './services/api.service';
import { FavIconService } from './services/fav-icon.service';
import { PromotionService } from './services/promotion.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messages: MessageJson = null;
  private scopes: ProfessionalScopesJson[] = [];
  private categories = [];

  constructor(
    private api: Api,
    public configService: ConfigService,
    private keycloakService: KeycloakService,
    private favIconService: FavIconService,
    private cookieService: CookieService,
    private gtManager: GoogleTagManagerService,
    private route: ActivatedRoute,
    private affiliateService: AffiliateService,
    private promotionService: PromotionService
  ) {}

  public getMessages(): MessageJson {
    return this.messages;
  }

  public getScopes(): ProfessionalScopesJson[] {
    return this.scopes;
  }

  public getScope(id: number): ProfessionalScopesJson {
    return this.scopes.filter((s) => s.id === id)[0];
  }

  getCategory(categoryId: number) {
    return this.categories.filter((c) => c.id === categoryId)[0];
  }

  init() {
    return this.initConfig().then(() =>
      this.initMessages().then(() => {
        this.affiliateService.initAffiliateConfig();
        this.promotionService.initPromotion();
        return this.initKeycloak();
      })
    );
  }

  public initConfig(): Promise<void | SiteConfiguration> {
    if (this.configService.config) {
      return Promise.resolve(this.configService.config);
    }
    const url = new URL(window.location.href);
    const aid = url.searchParams.get('aid');
    return firstValueFrom(this.api.getConfiguration(aid)).then((config) => {
      this.favIconService.initFavoriteIconsAndTitle(config);
      this.configService.config = config;
      this.configService.domain = window.location.hostname;
      if (config.tagManagerId) {
        this.gtManager.googleTagManagerConfiguration.set({
          id: config.tagManagerId
        });
        this.gtManager = new GoogleTagManagerService(
          this.gtManager.googleTagManagerConfiguration,
          config.tagManagerId,
          'silent',
          this.gtManager.googleTagManagerConfiguration.get().gtm_auth,
          this.gtManager.googleTagManagerConfiguration.get().gtm_preview,
          this.gtManager.googleTagManagerResourcePath,
          this.gtManager.googleTagManagerCSPNonce
        );
        this.gtManager.addGtmToDom().then(() => {});
      }
    });
  }

  public initMessages(): Promise<void | MessageJson> {
    if (this.messages) {
      return Promise.resolve(this.messages);
    }
    return firstValueFrom(this.api.getMessages('de')).then((messages) => {
      this.messages = messages;
      this.scopes.push(...this.messages.lawyerScopes);
      this.scopes.push(...this.messages.renoScopes);
      this.categories.push(...this.messages.scopeCategories);
      this.favIconService.initMetaData(messages);
    });
  }

  private initKeycloak(): Promise<any> {
    environment.keycloak.realm = this.configService.config.realm;
    environment.keycloak.clientId = this.configService.config.client;
    environment.keycloak.url = this.configService.getKeycloakUrl();

    return this.keycloakService.init({
      config: environment.keycloak,
      initOptions: {
        onLoad:
          this.cookieService.get('rfLoggedIn') === 'true' ? 'check-sso' : null,
        checkLoginIframe: false,
        flow: 'standard',
        pkceMethod: 'S256'
      },
      bearerExcludedUrls: environment.excludePath,
      loadUserProfileAtStartUp: true,
      enableBearerInterceptor: true
    });
  }
}
