<header>
  <nav
    class="navbar navbar-expand-lg navbar-light justify-content-between brand-box"
  >
    <div class="container-fluid page-header">
      <app-external-link [externalLink]="messages.mainDomain" [internalRoute]="'/'">
        <ng-container>
        <img
          [ngSrc]="getLogo()"
          alt="{{ messages.companylabel }}"
          class="logo brand"
          [ngClass]="{ 'logo-small-header': scrolled }"
          height="53"
          width="450"
        />
        <img
          ngSrc="/assets/{{ config.config.theme }}/logo-only.svg"
          alt="{{ messages.companylabel }}"
          class="logo-small"
          width="62"
          height="32"
          [ngClass]="{ 'logo-small-header': scrolled }"
        />
        </ng-container>
      </app-external-link>
      <div class="register-btn d-flex justify-content-end">
        <div *ngIf="getUserProfile() != null" class="d-flex">
          <div *ngIf="isBookmarkActive()" class="d-none d-lg-block">
            <a
              class="btn fw-bold text-secondary btn-header m-2 p-2"
              [routerLink]="['/customer-dashboard/merkzettel']"
            >
              <fa-icon [icon]="faListAlt"></fa-icon>
              {{ messages.bookmarkList }}
            </a>
          </div>
          <div class="d-none d-lg-block">
            <div class="dropdown" ngbDropdown placement="bottom-right">
              <a class="btn btn-block my-2 py-sm-0 my-sm-0" ngbDropdownToggle>
                <div
                  class="btn fw-bold text-secondary btn-header m-2 p-2"
                  (click)="toogleMenu()"
                  (keydown)="toogleMenu($event)"
                >
                  <fa-icon [icon]="faUser"></fa-icon>
                  {{ getUserProfile().firstName }}
                  {{ getUserProfile().lastName }}
                  <fa-icon
                    *ngIf="!menuOpen"
                    [icon]="faAngleDown"
                  ></fa-icon>
                  <fa-icon
                    *ngIf="menuOpen"
                    [icon]="faAngleUp"
                  ></fa-icon>
                </div>
                <div
                  class="dropdown-menu content-card right-menu"
                  ngbDropdownMenu
                >
                  <a
                    class="dropdown-item py-2"
                    [routerLink]="['/customer-dashboard']"
                  >
                    <fa-icon
                      class="me-3"
                      [icon]="faHome"
                      [fixedWidth]="true"
                    ></fa-icon>
                    {{ messages.overview }}
                  </a>
                  <a
                    *ngIf="config.isFeatureActive('EDUCATIONAL_STATE')"
                    class="dropdown-item py-2"
                    [routerLink]="['/customer-dashboard/mein-status']"
                  >
                    <fa-icon
                      class="me-3"
                      [icon]="faTasks"
                      [fixedWidth]="true"
                    ></fa-icon>
                    {{ messages.educationalState }}
                  </a>
                  <a
                    class="dropdown-item py-2"
                    [routerLink]="['/customer-dashboard/meine-kurse']"
                  >
                    <fa-icon
                      class="me-3"
                      [icon]="faCalendarAlt"
                      [fixedWidth]="true"
                    ></fa-icon>
                    {{ messages.myCourses }}
                  </a>
                  <a
                    class="dropdown-item py-2"
                    [routerLink]="['/customer-dashboard/favoriten']"
                  >
                    <fa-icon
                      class="me-3"
                      [icon]="faStar"
                      [fixedWidth]="true"
                    ></fa-icon>
                    {{ messages.searchFavorites }}
                  </a>
                  <a
                    class="dropdown-item py-2"
                    [routerLink]="['/customer-dashboard/meine-daten']"
                  >
                    <fa-icon
                      class="me-3"
                      [icon]="faAddressCard"
                      [fixedWidth]="true"
                    ></fa-icon>
                    {{ messages.myData }}
                  </a>
                  <div class="dropdown-divider"></div>
                  <div class="p-2">
                    <a
                      class="btn w-100 fw-bold btn-outline-secondary p-2"
                      (click)="logoutUser()"
                      (keydown)="logoutUser($event)"
                    >
                      <span class="me-2"> Abmelden </span>
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="getUserProfile() == null" class="d-flex">
          <div class="d-none d-lg-block">
            <button
              class="btn fw-bold text-secondary btn-header m-2 p-2"
              (click)="getAuthUrl()"
            >
              <span class="d-block">{{ messages.login }}</span>
            </button>
          </div>
          <div class="d-none d-lg-block">
            <button
              class="btn fw-bold text-secondary hover-white btn-outline-secondary m-2 p-2"
              (click)="getRegisterUrl()"
            >
              <span class="d-block">{{ messages.register }}</span>
            </button>
          </div>
        </div>
        <div *ngIf="showCart()" class="row ms-3">
          <a
            class="btn fw-bold text-secondary btn-header m-2 p-2"
            [routerLink]="['/warenkorb']"
          >
            <span class="d-block">
              <fa-icon [icon]="faShoppingCart" size="lg"></fa-icon>
              <div class="course-amount">{{ getCartAmount() }}</div>
            </span>
          </a>
        </div>
        <div class="mobile-nav d-block d-lg-none">
          <app-navigation mobile="true"></app-navigation>
        </div>
      </div>
    </div>
  </nav>

  <nav
    class="d-none d-lg-block navbar navbar-expand-lg justify-content-between nav-bar"
    [ngClass]="{ 'nav-bar-hidden': scrolled }"
  >
    <app-navigation></app-navigation>
  </nav>
</header>
