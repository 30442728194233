import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
// TODO: Navigation-End-Event ebenfalls berücksichtigen!
export class RequestEventService {
  public events: EventEmitter<string>;
  private runningRequests: Array<string> = [];

  constructor() {
    this.events = new EventEmitter();
  }

  public throw(event: string): void {
    event = this.getEventName(event);
    if (this.runningRequests.indexOf(event) < 0) {
      this.runningRequests.push(event);
      this.events.emit(event);
    }
  }

  public done(event: string) {
    event = this.getEventName(event);
    const index = this.runningRequests.indexOf(event);
    if (index >= 0) {
      this.runningRequests.splice(index, 1);
      this.events.emit(event);
    }
  }

  private getEventName(path: string): string {
    const startPos = path.indexOf('/api') !== -1 ? path.indexOf('/api') + 4 : 0;
    const endPos = path.indexOf('?') !== -1 ? path.indexOf('?') : path.length;
    return path.slice(startPos, endPos);
  }
}
