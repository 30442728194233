import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CourseOverviewJson } from '../../models/course-overview-json';
import { SearchRequestJson } from '../../models/search-request-json';
import { SearchResponseJson } from '../../models/search-response-json';
import { Api } from '../../services/api.service';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private api: Api,
    private spinnerService: SpinnerOverlayService
  ) {}

  private _courses: BehaviorSubject<Array<CourseOverviewJson>> =
    new BehaviorSubject([]);
  private _filterData: BehaviorSubject<SearchResponseJson> =
    new BehaviorSubject(new SearchResponseJson());
  private _resultCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  public readonly courses: Observable<Array<CourseOverviewJson>> =
    this._courses.asObservable();
  public filterData: Observable<SearchResponseJson> =
    this._filterData.asObservable();
  public resultCount: Observable<number> = this._resultCount.asObservable();
  isLoading = false;

  public static initSearchParameter(searchData: SearchRequestJson) {
    searchData.initial = true;
    sessionStorage.setItem('rf_search', JSON.stringify(searchData));
  }

  private static isSameSearch(searchReqValues: SearchRequestJson): boolean {
    return (
      JSON.stringify(searchReqValues) === sessionStorage.getItem('rf_search')
    );
  }

  public static saveSearchParameter(searchData: SearchRequestJson) {
    searchData.initial = false;
    sessionStorage.setItem('rf_search', JSON.stringify(searchData));
  }

  public static getSearchDefaultParameter(): SearchRequestJson {
    const searchParameter = {} as SearchRequestJson;
    searchParameter.start = 1;
    searchParameter.limit = 20;
    return searchParameter;
  }

  public static getSearchParameter(): SearchRequestJson {
    return JSON.parse(sessionStorage.getItem('rf_search'));
  }

  public static clearSearchData() {
    sessionStorage.removeItem('rf_search');
    sessionStorage.removeItem('rf_filter');
  }

  public static saveFilterData(searchData: SearchResponseJson) {
    searchData.courses = null;
    searchData.count = null;
    sessionStorage.setItem('rf_filter', JSON.stringify(searchData));
  }

  public static getFilterData(): SearchResponseJson {
    return JSON.parse(sessionStorage.getItem('rf_filter'));
  }

  static getSearchScope(): number {
    return this.getSearchParameter()?.scope;
  }

  static getSearchCategory() {
    return this.getSearchParameter()?.category;
  }

  static reinitSearch() {
    const searchParameter = this.getSearchParameter();
    if (searchParameter) {
      this.initSearchParameter(searchParameter);
    }
  }

  public setCourses(courses: Array<CourseOverviewJson>) {
    this._courses.next(courses);
  }

  search(searchReqValues) {
    if (!SearchService.isSameSearch(searchReqValues)) {
      this.spinnerService.show();
      SearchService.saveSearchParameter(searchReqValues);
      this.api.getSearch(searchReqValues).subscribe(
        (erg: SearchResponseJson) => {
          this.setCourses(erg.courses);
          this._filterData.next(erg);
          this.setResultCount(erg.count);
          this.spinnerService.hide();
        },
        (error) => {
          // TODO: Error-Handler -> Error-Response-Json
          console.warn('getSearchError: ', error);
          this.spinnerService.hide();
        }
      );
    }
  }

  newSearch(searchReqValues) {
    this.search(searchReqValues);
  }

  resetFilter() {
    this._filterData = new BehaviorSubject(new SearchResponseJson());
    this.filterData = this._filterData.asObservable();
  }

  loadResult() {
    this.isLoading = true;
    const searchParameter = SearchService.getSearchParameter();
    searchParameter.start = Number(searchParameter.start) + 1;
    SearchService.saveSearchParameter(searchParameter);
    this.api.getSearch(SearchService.getSearchParameter()).subscribe(
      (erg: SearchResponseJson) => {
        const combine = this._courses.getValue().concat(erg.courses);
        this.setCourses(combine);
        this.isLoading = false;
      },
      (error) => {
        // TODO: Error-Handler -> Error-Response-Json
        console.warn('getSearchError: ', error);
      }
    );
  }

  getLoadingStatus(): boolean {
    return this.isLoading;
  }

  setResultCount(number: number) {
    this._resultCount.next(number);
  }
}
