<div class="full row align-content-center">
  <div class="col-2"></div>
  <div class="col-6">
    <div class="row error-code font-italic mb-2">Wartung</div>
    <div class="row error-message">Zur Zeit führen wir Wartungsarbeiten durch!</div>
    <div class="row error-message">
      Wir sind so schnell wie möglich wieder für Sie da!
    </div>
  </div>
</div>
