<div class="main-content">
  <div>
    <app-header></app-header>
    <div *ngIf="!maintenance" class="page-content">
      <router-outlet (activate)="createCanonical()"></router-outlet>
    </div>
    <app-maitenance *ngIf="maintenance"></app-maitenance>
  </div>
  <app-footer></app-footer>
</div>
