import { AnswerJsonLd } from './AnswerJsonLd';
import {JsonLd} from "./json-ld";

export class QuestionJsonLd implements JsonLd {
  readonly '@context' = 'https://schema.org';
  readonly '@type' = 'Question';
  name: string;
  acceptedAnswer: AnswerJsonLd;
  [param: string]: string | Object;
}
