import { environmentCommon } from './environment-common';

const keycloakConfig: any = {
  'ssl-required': 'external',
  'use-resource-role-mappings': true,
  'confidential-port': 0
};
const theme = 'rf';

const prodEnv = {
  name: 'staging',
  keycloak: keycloakConfig,
  production: true,
  recaptcha: true,
  secure: true
};

export const environment = { ...prodEnv, ...environmentCommon };
