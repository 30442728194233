import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-maitenance',
  templateUrl: './maitenance.component.html',
  styleUrls: ['./maitenance.component.scss']
})
export class MaitenanceComponent {
  constructor() {}
}
