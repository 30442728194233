import { Component } from '@angular/core';
import {
  faFacebookSquare,
  faInstagram,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faXingSquare } from '@fortawesome/free-brands-svg-icons/faXingSquare';
import { ConfigService } from '../../config.service';
import { MessageService } from '../../message-service';
import { MessageJson } from '../../models/message-json';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NewsletterComponent } from '../newsletter/newsletter.component';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [CommonModule, RouterModule, FontAwesomeModule, NewsletterComponent]
})
export class FooterComponent {
  messages: MessageJson;

  faFacebookSquare = faFacebookSquare;
  faXingSquare = faXingSquare;
  faXTwitter = faXTwitter;
  faInstagram = faInstagram;

  constructor(
    private messageService: MessageService,
    public config: ConfigService
  ) {
    this.messages = this.messageService.getMessages();
  }

  getFooterLinks() {
    return this.config.config.footerParts.filter(
      (value) => value.type === 'LINK'
    );
  }

  getSocialMediaLinks() {
    return this.config.config.footerParts.filter(
      (value) => value.type === 'SOCIAL_MEDIA'
    );
  }
}
