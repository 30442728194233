import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
// import { JsonLdService } from 'ngx-seo';
import {forkJoin, Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ConfigService} from '../config.service';
import {PathEnum} from '../enums/path-enum';
import {MessageService} from '../message-service';
import {AggregatedRatingJsonLd} from '../models/aggregated-rating-json-ld';
import {CourseResponseJson} from '../models/course-response-json';
import {MessageJson} from '../models/message-json';
import {OrganizationJsonLd} from '../models/organization-json-ld';
import {UtilsService} from './utils.service';
import {CoursePackage} from '../../../build/course-api';
import {JsonLd} from "../models/json-ld";
import {JsonLdService} from "./json-ld.service";
import {CityService} from "./city.service";

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  messages: MessageJson;
  defaultTitle: string;
  shortTitle: string;

  constructor(
    @Inject(DOCUMENT) private dom,
    private meta: Meta,
    private titleService: Title,
    private cityService: CityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private jsonLdService: JsonLdService,
    private config: ConfigService
  ) {
    this.messages = messageService.getMessages();
    this.defaultTitle = this.messages.defaultSiteTitle;
    this.shortTitle = this.messages.defaultSiteTitleShort;
  }

  createCanonicalURL() {
    const url = this.getUrl(this.dom.URL);
    const linkElements = this.dom.getElementsByTagName('link');
    for (const element of linkElements) {
      if (element.getAttribute('rel') === 'canonical') {
        this.dom.head.removeChild(element);
      }
    }
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

  private getUrl(url: string) {
    if (url.startsWith('http:')) {
      url.replace('http:', 'https:');
    }
    if (url.endsWith('/index.html')) {
      url.replace('/index.html', '');
    }
    if (url.indexOf('?') !== -1) {
      url = url.substr(0, url.indexOf('?'));
    }
    const host = url.substr(8, url.indexOf('/', 9) - 8);
    if (!host.startsWith('www')) {
      url = 'https://www.' + host + url.substr(url.indexOf('/', 9));
    }
    return url;
  }

  updateMetaInfo(content) {
    this.meta.updateTag({name: 'description', content: content});
  }

  updateTitle(title: String) {
    if (title.length < 10) {
      this.titleService.setTitle(title + ' | ' + this.defaultTitle);
    } else {
      this.titleService.setTitle(title + ' | ' + this.shortTitle);
    }
  }

  initTitleChange() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary')
      )
      .subscribe((event) => {
        this.setJsonLd([]);
        const data: any = event.data;
        const title = data.value['title'];
        let type = 'Fortbildungen ';
        let additionalText = this.messages.metaAdditionalInfos;
        if (!title) {
          let category = data.value['category'];
          if (!category) {
            let toSet = true;
            toSet = this.setSearchTitle(event);
            category = this.activatedRoute.snapshot.firstChild.url[0]?.path;
            if (category) {
              if (category === PathEnum.fachanwaltskurs) {
                type = 'Fachanwaltslehrgänge ';
                additionalText = '';
              }
              toSet = this.setCategoryPageData(
                category,
                toSet,
                event,
                type,
                additionalText
              );
              toSet = this.setCourseData(category, toSet, event);
              toSet = this.setPackageDate(category, toSet, event);
              toSet = toSet = this.setOnlineSelfPageData(
                category,
                toSet,
                event,
                additionalText
              );
              toSet = this.setOrganizerPageData(category, toSet, event);
            }
            if (toSet) {
              this.setDefaultData();
            }
          } else {
            switch (category) {
              case 'city':
                this.setCityPageData(type, event, additionalText);
                break;
              case PathEnum.online:
                this.setOnlineSelfPageData(category, true, event, additionalText);
                break;
              case PathEnum.present:
                this.setPresentPageData(category, true, event, additionalText);
                break;
              default:
                this.setDefaultData();
            }
          }
        } else {
          this.updateMetaInfo(this.messages.defaultMetaDescription);
          if (title.length < 10) {
            this.titleService.setTitle(title + ' | ' + this.defaultTitle);
          } else {
            this.titleService.setTitle(title + ' | ' + this.shortTitle);
          }
        }
      });
  }

  private setDefaultData() {
    this.updateMetaInfo(this.messages.defaultMetaDescription);
    if (this.activatedRoute.outlet === 'primary') {
      const routeData = this.activatedRoute.data;
      routeData.subscribe((routeEvent) => {
        if (routeEvent['title']) {
          this.titleService.setTitle(
            routeEvent['title'] + ' | ' + this.shortTitle
          );
        } else {
          this.titleService.setTitle(this.defaultTitle);
        }
      });
    }
  }

  public setJsonLd(jsonLd?: JsonLd[]) {
    const logoJson: OrganizationJsonLd = new OrganizationJsonLd();
    logoJson.name = this.config.config.name;
    logoJson.url = this.config.getDomain();
    logoJson.logo =
      this.config.getDomain() +
      '/assets/' +
      this.config.config.theme +
      '/logo_small.png';
    const socialMedia = this.config.config.socialMedia;
    if (Object.keys(socialMedia).length > 0) {
      logoJson.sameAs = [];
      for (const sm of Object.values(socialMedia)) {
        logoJson.sameAs.push(sm);
      }
    }
    if (this.config.config.reviews) {
      const rating = new AggregatedRatingJsonLd();
      rating.ratingCount = this.config.config.reviews;
      rating.ratingValue = this.config.config.overallRating;
      logoJson.aggregateRating = rating;
    }
    this.jsonLdService.setData([logoJson, ...jsonLd]);
  }

  initEntryPoint() {
    this.clearEntryPoint();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((route) => {
          return route;
        })
      )
      .subscribe((event) => {
        this.setEntryPoint(event);
      });
  }

  private setOnlineSelfPageData(
    category,
    toSet: boolean,
    event: ActivatedRoute,
    additionalText: string
  ) {
    if (
      toSet &&
      (category === PathEnum.online || category === PathEnum.selfstudies)
    ) {
      toSet = false;
      let metaDescription = this.messages.metaDescriptionIntro;
      let headLineTmp = 'Onlineseminare';
      if (category === PathEnum.selfstudies) {
        headLineTmp = 'Selbststudium';
        metaDescription += 'im Selbststudium ';
      } else {
        metaDescription += 'als Onlinekurs ';
      }
      if (event.snapshot.paramMap.get('selectedScopeName')) {
        const id: number = UtilsService.getIdFromPath(
          event.snapshot.paramMap.get('selectedScopeName')
        );
        const result = this.messageService.getScope(id);
        const scopeName = result.name;
        headLineTmp = headLineTmp + ' im ' + scopeName;
        metaDescription +=
          '| Alle Kurse im ' + scopeName + ' auf einen Blick | ';
        metaDescription += ' Einfach & schnell vergleichen | JETZT BUCHEN';
        this.updateMetaInfo(metaDescription);
        this.titleService.setTitle(headLineTmp + additionalText);
      } else {
        metaDescription += '| Alle Kurse im auf einen Blick | ';
        metaDescription += ' Einfach & schnell vergleichen | JETZT BUCHEN';
        this.updateMetaInfo(metaDescription);
        this.titleService.setTitle(headLineTmp + additionalText);
      }
    }
    return toSet;
  }

  private setCategoryPageData(
    category: string,
    toSet: boolean,
    event: ActivatedRoute,
    type: string,
    additionalText: string
  ) {
    if (
      toSet &&
      (category === PathEnum.fachanwaltskurs ||
        category.startsWith('fortbildungen'))
    ) {
      let metaDescription;
      toSet = false;
      const selectedScope = event.snapshot.paramMap.get('selectedScopeName');
      if (selectedScope) {
        const id: number = UtilsService.getIdFromPath(selectedScope);
        const result = this.messageService.getScope(id);
        const selectedScopeName = result.name;
        if (category.startsWith('fortbildungen')) {
          metaDescription = this.messages.educationMetaDescription;
        } else {
          metaDescription = this.messages.specializationMetaDescription;
        }
        metaDescription = metaDescription.replace(
          '$scope',
          'im ' + selectedScopeName
        );
        this.updateMetaInfo(metaDescription);
        this.titleService.setTitle(
          type + ' im ' + selectedScopeName + additionalText
        );
      } else {
        if (category.startsWith('fortbildungen')) {
          additionalText = this.messages.metaAdditionalInfos;
          metaDescription = this.messages.educationOverviewMetaDescription;
        } else {
          metaDescription = this.messages.specializationMetaDescription;
        }
        additionalText += ' im Überblick';
        metaDescription = metaDescription.replace('$scope ', '');
        this.updateMetaInfo(metaDescription);
        this.titleService.setTitle(type + additionalText);
      }
    }
    return toSet;
  }

  private setCityPageData(
    type: string,
    event: ActivatedRoute,
    additionalText: string
  ) {
    let metaDescription = this.messages.metaDescriptionIntro;
    let headLineTmp = type;
    const cityId = UtilsService.getIdFromPath(
      event.snapshot.paramMap.get('cityName')
    );
    const city = this.cityService.getCity(cityId);
    let scope;
    const selectedScope = event.snapshot.paramMap.get('selectedScopeName');
    if (selectedScope) {
      const id: number = UtilsService.getIdFromPath(selectedScope);
      scope = this.messageService.getScope(id);
    }
    let fork: Observable<unknown[]>;
    if (scope) {
      fork = forkJoin([city, of(scope)]);
    } else {
      fork = forkJoin([city]);
    }
    fork.subscribe((result) => {
      const scopeName: any = result[1];
      const cityName: any = result[0];
      if (scopeName) {
        headLineTmp = headLineTmp + ' im ' + scopeName.name;
        metaDescription += ' im ' + scopeName.name;
      }
      if (cityName) {
        headLineTmp = headLineTmp + ' in ' + cityName.name;
        metaDescription += ' in ' + cityName.name;
      }
      metaDescription +=
        ' | Alle Kurse und Termine auf einen Blick |  Einfach & schnell vergleichen | JETZT BUCHEN';
      this.updateMetaInfo(metaDescription);
      if (additionalText) {
        headLineTmp += additionalText
      }
      this.titleService.setTitle(headLineTmp);
    });
  }

  private setCourseData(category, toSet: boolean, event: ActivatedRoute) {
    if (
      toSet &&
      (category.startsWith('fortbildung') || category === 'fachanwaltslehrgang')
    ) {
      toSet = false;
      if (event.snapshot.data.certificationData) {
        this.titleService.setTitle(
          'Lernerfolgskontrolle ' +
          event.snapshot.data.certificationData.courseName
        );
      } else {
        const course: CourseResponseJson = event.snapshot.data.details;
        let title = course.name;
        if (course.courseSchedules.length === 1) {
          title += " (" + course.courseSchedules[0].city.trim()
          if (course.courseSchedules[0].city.toLowerCase().includes("online")) {
            title += " - " + UtilsService.formatDate('dd.MM.yyyy', course.courseSchedules[0].startDate);
          }
          title += ")";
        }
        this.titleService.setTitle(title);
        this.updateMetaInfo(course.meta);
      }
    }
    return toSet;
  }

  private setSearchTitle(event: ActivatedRoute) {
    let toSet = true;
    if (
      event.snapshot.queryParams &&
      event.snapshot.queryParams['selectedScopeName']
    ) {
      const scope = event.snapshot.queryParams['selectedScopeName'];
      if (scope) {
        let type = '';
        if (event.snapshot.queryParams['types'] === 'EDUCATION') {
          type = 'Seminare ';
        }
        if (event.snapshot.queryParams['types'] === 'SPECIALIZATION') {
          type = 'Fachanwaltslehrgänge ';
        }
        this.titleService.setTitle('Suchergebnisse ' + type + scope);
        toSet = false;
      }
    }
    return toSet;
  }

  private setOrganizerPageData(
    category: any,
    toSet: boolean,
    event: ActivatedRoute
  ) {
    if (toSet && category === 'veranstalter') {
      const organizer = event.snapshot.data.organizer;
      const metaDescription =
        organizer.name +
        ' - Alle Seminare auf einen Blick. Jetzt mit anderen Anbietern vergleichen und bequem buchen.';
      this.updateMetaInfo(metaDescription);
      toSet = false;
    }
    return toSet;
  }

  clearEntryPoint() {
    localStorage.removeItem('entry');
  }

  private setEntryPoint<B>(event: any) {
    if (!localStorage.getItem('entry')) {
      localStorage.setItem('entry', this.router.routerState.snapshot.url);
    } else {
      localStorage.setItem('entry', '/');
    }
  }

  private setPackageDate(
    category: string,
    toSet: boolean,
    event: ActivatedRoute
  ) {
    if (toSet && category.startsWith('seminar-pakete')) {
      const packageData: CoursePackage = event.snapshot.data.package;
      if (packageData) {
        toSet = false;
        this.titleService.setTitle(packageData.name);
        this.updateMetaInfo(packageData.name);
      }
    }
    return toSet;
  }

  private setPresentPageData(category: any, toSet: boolean, event: ActivatedRoute, additionalText: string) {
    if (toSet && category === PathEnum.present) {
      toSet = false;
      let metaDescription = this.messages.metaDescriptionIntro;
      let headLineTmp = 'Präsenzschulungen';
      metaDescription += 'als Präsenzschulung ';
      if (event.snapshot.paramMap.get('selectedScopeName')) {
        const id: number = UtilsService.getIdFromPath(
          event.snapshot.paramMap.get('selectedScopeName')
        );
        const result = this.messageService.getScope(id);
        const scopeName = result.name;
        headLineTmp = headLineTmp + ' im ' + scopeName;
        metaDescription +=
          '| Alle Kurse im ' + scopeName + ' auf einen Blick | ';
        metaDescription += ' Einfach & schnell vergleichen | JETZT BUCHEN';
        this.updateMetaInfo(metaDescription);
        this.titleService.setTitle(headLineTmp + additionalText);
      } else {
        metaDescription += '| Alle Kurse im auf einen Blick | ';
        metaDescription += ' Einfach & schnell vergleichen | JETZT BUCHEN';
        this.updateMetaInfo(metaDescription);
        this.titleService.setTitle(headLineTmp + additionalText);
      }
    }
    return toSet;
  }
}
