import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, retryWhen } from 'rxjs/operators';
import { RequestEventService } from './request-event.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
  public events: EventEmitter<string>;
  private excludedStatusCode = [402, 406];

  constructor(
    private requestEventService: RequestEventService,
    private router: Router,
    private keycloakService: KeycloakService
  ) {
    this.events = new EventEmitter();
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestEventService.throw(request.url.toString());
    return this.subscribeToResponse(next, request);
  }

  private subscribeToResponse(next: HttpHandler, request: HttpRequest<any>) {
    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((errorResponse, count) => {
            if (count <= 2 && !this.excludedStatusCode.includes(errorResponse.status)) {
              return of(errorResponse);
            }
            return throwError(errorResponse);
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          this.requestEventService.throw('error');
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else if (error.status === 404) {
            const blog = '/blog';
            // Exclude interceptor for blog request:
            if (request.url.search(blog) === -1) {
              window.location.href = '/404';
            }
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          return throwError(error);
        } else {
          console.error('Access denied. Try to log in.');
          this.keycloakService.updateToken().then(
            (value) => {
              if (value) {
                console.debug('token refreshed');
                next.handle(request);
              } else {
                console.info('token refesh failed');
                this.keycloakService.login();
              }
            },
            (reason) => {
              console.info('token could not be refreshed', reason);
              this.keycloakService.login();
            }
          );
        }
      }),
      map((event: HttpEvent<any>) => {
        this.handleResponse(event);
        return event;
      })
    );
  }

  private handleResponse(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      this.requestEventService.done(event.url.toString());
    }
  }
}
