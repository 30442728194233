<div class="text-center">
  <div class="spinner spinner-1" [ngClass]="{ 'mt-2': style === 'thin' }"></div>
  <div
    [ngClass]="{
      'font-size-big my-2': style === 'thin',
      'search-text': style !== 'thin'
    }"
  >
    {{ message }}
  </div>
</div>
