import { Routes } from '@angular/router';
import { CheckComponent } from './order/check/check.component';
import { Error404Component } from './pages/error404/error404.component';
import { StartComponent } from './pages/start/start.component';
import { CoursePreviewOrganizerResolverService } from './resolver/course-preview-organizer-resolver.service';
import { CoursePreviewResolver } from './resolver/course-preview-resolver.service';
import { KeycloakGuard } from './user/keycloak.guard';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: StartComponent,
    resolve: {
      coursePreview: CoursePreviewResolver,
      organizers: CoursePreviewOrganizerResolverService
    }
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.routes').then((m) => m.searchRoutes)
  },
  {
    path: 'fachanwaltslehrgaenge',
    loadChildren: () =>
      import('./lehrgang-search/lehrgang-search.routes').then(
        (m) => m.lehrgangSearchRoutes
      )
  },
  {
    path: 'fortbildungen',
    loadChildren: () =>
      import('./course-search/coursesSearchRoutes').then(
        (m) => m.coursesSearchRoutes
      )
  },
  {
    path: 'fortbildungen-15-fao',
    loadChildren: () =>
      import('./course-search/coursesSearchRoutes').then(
        (m) => m.coursesSearchRoutes
      )
  },
  {
    path: 'online-seminare-mitarbeiter',
    loadChildren: () =>
      import('./online-course/online-course.routes').then(
        (m) => m.onlineCourseRoutes
      )
  },
  {
    path: 'online-seminare',
    loadChildren: () =>
      import('./online-course/online-course.routes').then(
        (m) => m.onlineCourseRoutes
      )
  },
  {
    path: 'praesenzschulung',
    loadChildren: () =>
      import('./present-courses/present-course.routes').then(
        (m) => m.presentCourseRoutes
      )
  },
  {
    path: 'seminar-pakete',
    loadChildren: () =>
      import('./course-package/course-package.routes').then(
        (m) => m.packageRoutes
      )
  },
  {
    path: 'selbststudium',
    loadChildren: () =>
      import('./self-studies/self-studies.routes').then(
        (m) => m.selfStudiesRoutes
      )
  },
  {
    path: 'selbststudium-mitarbeiter',
    loadChildren: () =>
      import('./self-studies/self-studies.routes').then(
        (m) => m.selfStudiesRoutes
      )
  },
  {
    path: 'stadt',
    loadChildren: () => import('./city/city.routes').then((m) => m.cityRoutes)
  },
  {
    path: 'fachanwaltslehrgang',
    loadChildren: () =>
      import('./lehrgang/lehrgang.routes').then((m) => m.lehrgangRoutes)
  },
  {
    path: 'fortbildung',
    loadChildren: () =>
      import('./course/courses.routes').then((m) => m.coursesRoutes)
  },
  {
    path: 'fortbildung-15-fao',
    loadChildren: () =>
      import('./course/courses.routes').then((m) => m.coursesRoutes)
  },
  {
    path: 'customer-dashboard',
    loadChildren: () =>
      import('./customer-dashboard/customer-dashboard.routes').then(
        (m) => m.customerDashboardRoutes
      )
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./order/order.routes').then((m) => m.orderRoutes)
  },
  {
    path: 'guest-order',
    loadChildren: () =>
      import('./order/order.routes').then((m) => m.orderRoutes)
  },
  {
    path: 'veranstalter',
    loadChildren: () =>
      import('./organizer/organizer.routes').then((m) => m.organizerRoutes)
  },
  {
    path: 'warenkorb',
    loadChildren: () => import('./cart/cart.routes').then((m) => m.cartRoutes)
  },
  {
    path: 'referenten',
    loadChildren: () =>
      import('./referents/referents.routes').then((m) => m.referentsRoutes)
  },
  {
    path: 'newsletter-confirmation',
    loadChildren: () =>
      import(
        './pages/newsletter-confirmation/newsletter-confirmation.routes'
      ).then((m) => m.newsletterConformationRoutes)
  },
  {
    path: 'check',
    pathMatch: 'full',
    canActivate: [KeycloakGuard],
    component: CheckComponent
  },
  {
    path: 'veranstalter-informationen',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'agb',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'datenschutz',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'fachanwaltsordnung',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'impressum',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'ueber-uns',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./static-content/static-content.routes').then(
        (m) => m.staticContentRoutes
      )
  },
  {
    path: 'teilnehmer-feedback',
    loadChildren: () =>
      import('./evaluation/evaluation.routes').then((m) => m.evaluationRoutes)
  },
  { path: '404', component: Error404Component },
  { path: '**', redirectTo: '404' }
];
