import {Injectable} from "@angular/core";
import {Api} from "./api.service";
import {SuggestCityJson} from "../models/suggest-city-json";
import {of} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private cityMap: Map<string, SuggestCityJson> = new Map<string, SuggestCityJson>();
  private topCities: SuggestCityJson[]

  constructor(private api: Api,) {
  }

  getCities() {
    if (this.topCities) {
      return of(this.topCities);
    }
    return this.api.getCity().pipe(map((c) => {
      this.topCities = c
      c.forEach(city => this.cityMap.set(city.id.toString(), city));
      return c;
    }));
  }

  public getCity(id: number) {
    if (this.cityMap.has(id.toString())) {
      return of(this.cityMap.get(id.toString()))
    }
    return this.api.getCityName(id).pipe(map((city) => {
      this.cityMap.set(city.id.toString(), city);
      return city;
    }));
  }

}
