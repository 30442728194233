<div class="container mt-4 mb-4">
  <div class="col-12 bg-white border border-radius mx-auto p-3">
    <h1 class="headline">H&auml;ufige Fragen</h1>
    <p [innerHTML]="messages.faqIntro | safeHtml"></p>
  </div>
  <div class="faq">
    <ng-container
      *ngFor="let entry of faqMap | keyvalue: asIsOrder; let i = index"
    >
      <h2 class="faq-headline" *ngIf="entry.key === 'COMMON'">
        {{ messages.commonQuestions }}
      </h2>
      <h2 class="faq-headline" *ngIf="entry.key === 'BOOKING'">
        {{ messages.bookingQuestions }}
      </h2>
      <ngb-accordion #acc="ngbAccordion">
        <ngb-panel *ngFor="let value of entry.value">
          <ng-template ngbPanelTitle>
            {{ value.question }}
          </ng-template>
          <ng-template ngbPanelContent>
            <div
              class="faq-content"
              [innerHtml]="value.answer | safeHtml"
              appRouteTransformer
            ></div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>
  </div>
</div>
