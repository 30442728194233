import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localDEExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { KeycloakAngularModule } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { MessageService } from './message-service';
import { CustomGetInterceptorService } from './services/custom-get-interceptor.service';
import { DateService } from './services/date.service';
import { RequestInterceptorService } from './services/request-interceptor.service';
import {
  ApiModule as CourseApi,
  Configuration as CourseConfiguration
} from '../../build/course-api';
import {
  ApiModule as EvaluationApi,
  Configuration as EvaluationConfiguration
} from '../../build/evaluation-api';
import {
  ApiModule as WaitingListApi,
  Configuration as WaitingListConfiguration
} from '../../build/waiting-list-api';
import { HeaderComponent } from './page-elements/header/header.component';
import { FooterComponent } from './page-elements/footer/footer.component';
import { MaitenanceComponent } from './maintenance/maitenance/maitenance.component';

registerLocaleData(localeDE, 'de', localDEExtra);

export function init_config(messageResolver: MessageService) {
  return () => messageResolver.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    KeycloakAngularModule,
    HttpClientModule,
    GoogleTagManagerModule.forRoot({ id: 'XX' }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    CourseApi.forRoot(() => {
      return new CourseConfiguration({
        basePath: ``
      });
    }),
    EvaluationApi.forRoot(() => {
      return new EvaluationConfiguration({
        basePath: ``
      });
    }),
    WaitingListApi.forRoot(() => {
      return new WaitingListConfiguration({
        basePath: ``
      });
    }),
    HeaderComponent,
    FooterComponent,
    MaitenanceComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: NgbDateParserFormatter, useClass: DateService },
    {
      provide: APP_INITIALIZER,
      useFactory: init_config,
      deps: [MessageService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomGetInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    CookieService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
