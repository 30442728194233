import { Component } from '@angular/core';
import { SearchService } from './search/search/search.service';
import { MetaService } from './services/meta-service';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ConfigService } from './config.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  public maintenance = false;

  constructor(
    private metaService: MetaService,
    private configurationService: ConfigService,
    router: Router,
    viewportScroller: ViewportScroller
  ) {
    if (!configurationService.config.maintenance) {
      SearchService.reinitSearch();
      this.metaService.initTitleChange();
      this.metaService.initEntryPoint();
      router.events
        .pipe(filter((e): e is Scroll => e instanceof Scroll))
        .subscribe((e) => {
          if (e.position) {
            // backward navigation
            setTimeout(() => {
              viewportScroller.scrollToPosition(e.position);
            }, 0);
          } else if (e.anchor) {
            // anchor navigation
            setTimeout(() => {
              viewportScroller.scrollToAnchor(e.anchor);
            }, 0);
          } else {
            // forward navigation
            setTimeout(() => {
              viewportScroller.scrollToPosition([0, 0]);
            }, 0);
          }
        });
    } else {
      this.maintenance = true;
    }
  }

  createCanonical() {
    this.metaService.createCanonicalURL();
  }
}
