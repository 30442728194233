export declare interface Country {
  iso: string;
  iso2: string
  name: string;
  priority: number;
}

export const countries: Country[] = [
  {iso: 'IOT', iso2: undefined, name: 'Britisches Territorium im Indischen Ozean', priority: 0},
  {iso: 'BRN', iso2: undefined, name: 'Brunei', priority: 0},
  {iso: 'BGR', iso2: undefined, name: 'Bulgarien', priority: 0},
  {iso: 'BFA', iso2: undefined, name: 'Burkina Faso', priority: 0},
  {iso: 'BDI', iso2: undefined, name: 'Burundi', priority: 0},
  {iso: 'CHL', iso2: undefined, name: 'Chile', priority: 0},
  {iso: 'CHN', iso2: undefined, name: 'Volksrepublik China', priority: 0},
  {iso: 'CPT', iso2: undefined, name: 'Clipperton', priority: 0},
  {iso: 'COK', iso2: undefined, name: 'Cookinseln', priority: 0},
  {iso: 'CRI', iso2: undefined, name: 'Costa Rica', priority: 0},
  {iso: 'CUW', iso2: undefined, name: 'Curaçao', priority: 0},
  {iso: 'DNK', iso2: 'DK', name: 'Dänemark', priority: 0},
  {iso: 'DEU', iso2: 'DE', name: 'Deutschland', priority: 1},
  {iso: 'DGA', iso2: undefined, name: 'Diego Garcia', priority: 0},
  {iso: 'DMA', iso2: undefined, name: 'Dominica', priority: 0},
  {iso: 'DOM', iso2: undefined, name: 'Dominikanische Republik', priority: 0},
  {iso: 'DJI', iso2: undefined, name: 'Dschibuti', priority: 0},
  {iso: 'ECU', iso2: undefined, name: 'Ecuador', priority: 0},
  {iso: 'CIV', iso2: undefined, name: 'Elfenbeinküste', priority: 0},
  {iso: 'SLV', iso2: undefined, name: 'El Salvador', priority: 0},
  {iso: 'ERI', iso2: undefined, name: 'Eritrea', priority: 0},
  {iso: 'EST', iso2: 'EE', name: 'Estland', priority: 0},
  {iso: 'SWZ', iso2: undefined, name: 'Eswatini', priority: 0},
  {iso: 'FLK', iso2: undefined, name: 'Falklandinseln', priority: 0},
  {iso: 'FRO', iso2: undefined, name: 'Färöer', priority: 0},
  {iso: 'FJI', iso2: undefined, name: 'Fidschi', priority: 0},
  {iso: 'FIN', iso2: 'FI', name: 'Finnland', priority: 0},
  {iso: 'FRA', iso2: 'FR', name: 'Frankreich', priority: 0},
  {iso: 'GUF', iso2: undefined, name: 'Französisch-Guayana', priority: 0},
  {iso: 'PYF', iso2: undefined, name: 'Französisch-Polynesien', priority: 0},
  {iso: 'ATF', iso2: undefined, name: 'Französische Süd- und Antarktisgebiete', priority: 0},
  {iso: 'GAB', iso2: undefined, name: 'Gabun', priority: 0},
  {iso: 'GMB', iso2: undefined, name: 'Gambia', priority: 0},
  {iso: 'GEO', iso2: 'GE', name: 'Georgien', priority: 0},
  {iso: 'GHA', iso2: undefined, name: 'Ghana', priority: 0},
  {iso: 'GIB', iso2: undefined, name: 'Gibraltar', priority: 0},
  {iso: 'GRD', iso2: undefined, name: 'Grenada', priority: 0},
  {iso: 'GRC', iso2: 'GR', name: 'Griechenland', priority: 0},
  {iso: 'GRL', iso2: undefined, name: 'Grönland', priority: 0},
  {iso: 'GLP', iso2: undefined, name: 'Guadeloupe', priority: 0},
  {iso: 'GUM', iso2: undefined, name: 'Guam', priority: 0},
  {iso: 'GTM', iso2: undefined, name: 'Guatemala', priority: 0},
  {iso: 'GGY', iso2: undefined, name: 'Guernsey', priority: 0},
  {iso: 'GIN', iso2: undefined, name: 'Guinea', priority: 0},
  {iso: 'GNB', iso2: undefined, name: 'Guinea-Bissau', priority: 0},
  {iso: 'GUY', iso2: undefined, name: 'Guyana', priority: 0},
  {iso: 'HTI', iso2: undefined, name: 'Haiti', priority: 0},
  {iso: 'HMD', iso2: undefined, name: 'Heard und McDonaldinseln', priority: 0},
  {iso: 'HND', iso2: undefined, name: 'Honduras', priority: 0},
  {iso: 'HKG', iso2: undefined, name: 'Hongkong', priority: 0},
  {iso: 'IND', iso2: undefined, name: 'Indien', priority: 0},
  {iso: 'IDN', iso2: undefined, name: 'Indonesien', priority: 0},
  {iso: 'IMN', iso2: undefined, name: 'Isle of Man', priority: 0},
  {iso: 'IRQ', iso2: undefined, name: 'Irak', priority: 0},
  {iso: 'IRN', iso2: undefined, name: 'Iran', priority: 0},
  {iso: 'IRL', iso2: 'IE', name: 'Irland', priority: 0},
  {iso: 'ISL', iso2: 'IS', name: 'Island', priority: 0},
  {iso: 'ISR', iso2: 'IL', name: 'Israel', priority: 0},
  {iso: 'ITA', iso2: 'IT', name: 'Italien', priority: 0},
  {iso: 'JAM', iso2: undefined, name: 'Jamaika', priority: 0},
  {iso: 'JPN', iso2: undefined, name: 'Japan', priority: 0},
  {iso: 'YEM', iso2: undefined, name: 'Jemen', priority: 0},
  {iso: 'JEY', iso2: undefined, name: 'Jersey', priority: 0},
  {iso: 'JOR', iso2: undefined, name: 'Jordanien', priority: 0},
  {iso: 'CYM', iso2: undefined, name: 'Cayman Islands', priority: 0},
  {iso: 'KHM', iso2: undefined, name: 'Kambodscha', priority: 0},
  {iso: 'CMR', iso2: undefined, name: 'Kamerun', priority: 0},
  {iso: 'CAN', iso2: undefined, name: 'Kanada', priority: 0},
  {iso: 'CPV', iso2: undefined, name: 'Kap Verde', priority: 0},
  {iso: 'KAZ', iso2: undefined, name: 'Kasachstan', priority: 0},
  {iso: 'QAT', iso2: undefined, name: 'Katar', priority: 0},
  {iso: 'KEN', iso2: undefined, name: 'Kenia', priority: 0},
  {iso: 'KGZ', iso2: undefined, name: 'Kirgisistan', priority: 0},
  {iso: 'KIR', iso2: undefined, name: 'Kiribati', priority: 0},
  {iso: 'CCK', iso2: undefined, name: 'Kokosinseln', priority: 0},
  {iso: 'COL', iso2: undefined, name: 'Kolumbien', priority: 0},
  {iso: 'COM', iso2: undefined, name: 'Komoren', priority: 0},
  {iso: 'COD', iso2: undefined, name: 'Demokratische Republik Kongo', priority: 0},
  {iso: 'COG', iso2: undefined, name: 'Republik Kongo', priority: 0},
  {iso: 'PRK', iso2: undefined, name: 'Nordkorea', priority: 0},
  {iso: 'KOR', iso2: undefined, name: 'Südkorea', priority: 0},
  {iso: 'HRV', iso2: undefined, name: 'Kroatien', priority: 0},
  {iso: 'CUB', iso2: undefined, name: 'Kuba', priority: 0},
  {iso: 'KWT', iso2: undefined, name: 'Kuwait', priority: 0},
  {iso: 'LAO', iso2: undefined, name: 'Laos', priority: 0},
  {iso: 'LSO', iso2: undefined, name: 'Lesotho', priority: 0},
  {iso: 'LVA', iso2: undefined, name: 'Lettland', priority: 0},
  {iso: 'LBN', iso2: undefined, name: 'Libanon', priority: 0},
  {iso: 'LBR', iso2: undefined, name: 'Liberia', priority: 0},
  {iso: 'LBY', iso2: undefined, name: 'Libyen', priority: 0},
  {iso: 'LIE', iso2: 'LI', name: 'Liechtenstein', priority: 0},
  {iso: 'LTU', iso2: 'LT', name: 'Litauen', priority: 0},
  {iso: 'LUX', iso2: 'LU', name: 'Luxemburg', priority: 1},
  {iso: 'MAC', iso2: undefined, name: 'Macau', priority: 0},
  {iso: 'MDG', iso2: undefined, name: 'Madagaskar', priority: 0},
  {iso: 'MWI', iso2: undefined, name: 'Malawi', priority: 0},
  {iso: 'MYS', iso2: undefined, name: 'Malaysia', priority: 0},
  {iso: 'MDV', iso2: undefined, name: 'Malediven', priority: 0},
  {iso: 'MLI', iso2: undefined, name: 'Mali', priority: 0},
  {iso: 'MLT', iso2: undefined, name: 'Malta', priority: 0},
  {iso: 'MAR', iso2: undefined, name: 'Marokko', priority: 0},
  {iso: 'MHL', iso2: undefined, name: 'Marshallinseln', priority: 0},
  {iso: 'MTQ', iso2: undefined, name: 'Martinique', priority: 0},
  {iso: 'MRT', iso2: undefined, name: 'Mauretanien', priority: 0},
  {iso: 'MUS', iso2: undefined, name: 'Mauritius', priority: 0},
  {iso: 'MYT', iso2: undefined, name: 'Mayotte', priority: 0},
  {iso: 'MEX', iso2: undefined, name: 'Mexiko', priority: 0},
  {iso: 'FSM', iso2: undefined, name: 'Föderierte Staaten von Mikronesien', priority: 0},
  {iso: 'MDA', iso2: undefined, name: 'Moldau', priority: 0},
  {iso: 'MCO', iso2: undefined, name: 'Monaco', priority: 0},
  {iso: 'MNG', iso2: undefined, name: 'Mongolei', priority: 0},
  {iso: 'MNE', iso2: undefined, name: 'Montenegro', priority: 0},
  {iso: 'MSR', iso2: undefined, name: 'Montserrat', priority: 0},
  {iso: 'MOZ', iso2: undefined, name: 'Mosambik', priority: 0},
  {iso: 'MMR', iso2: undefined, name: 'Myanmar', priority: 0},
  {iso: 'NAM', iso2: undefined, name: 'Namibia', priority: 0},
  {iso: 'NRU', iso2: undefined, name: 'Nauru', priority: 0},
  {iso: 'NPL', iso2: undefined, name: 'Nepal', priority: 0},
  {iso: 'NCL', iso2: undefined, name: 'Neukaledonien', priority: 0},
  {iso: 'NZL', iso2: undefined, name: 'Neuseeland', priority: 0},
  {iso: 'NIC', iso2: undefined, name: 'Nicaragua', priority: 0},
  {iso: 'NLD', iso2: 'NL', name: 'Niederlande', priority: 0},
  {iso: 'NER', iso2: undefined, name: 'Niger', priority: 0},
  {iso: 'NGA', iso2: undefined, name: 'Nigeria', priority: 0},
  {iso: 'NIU', iso2: undefined, name: 'Niue', priority: 0},
  {iso: 'MNP', iso2: undefined, name: 'Nördliche Marianen', priority: 0},
  {iso: 'MKD', iso2: undefined, name: 'Nordmazedonien', priority: 0},
  {iso: 'NFK', iso2: undefined, name: 'Norfolkinsel', priority: 0},
  {iso: 'NOR', iso2: undefined, name: 'Norwegen', priority: 0},
  {iso: 'OMN', iso2: undefined, name: 'Oman', priority: 0},
  {iso: 'AUT', iso2: 'AT', name: 'Österreich', priority: 1},
  {iso: 'TLS', iso2: undefined, name: 'Osttimor', priority: 0},
  {iso: 'PAK', iso2: undefined, name: 'Pakistan', priority: 0},
  {iso: 'PSE', iso2: undefined, name: 'Palästina', priority: 0},
  {iso: 'PLW', iso2: undefined, name: 'Palau', priority: 0},
  {iso: 'PAN', iso2: undefined, name: 'Panama', priority: 0},
  {iso: 'PNG', iso2: undefined, name: 'Papua-Neuguinea', priority: 0},
  {iso: 'PRY', iso2: undefined, name: 'Paraguay', priority: 0},
  {iso: 'PER', iso2: undefined, name: 'Peru', priority: 0},
  {iso: 'PHL', iso2: undefined, name: 'Philippinen', priority: 0},
  {iso: 'PCN', iso2: undefined, name: 'Pitcairninseln', priority: 0},
  {iso: 'POL', iso2: 'PL', name: 'Polen', priority: 1},
  {iso: 'PRT', iso2: undefined, name: 'Portugal', priority: 0},
  {iso: 'PRI', iso2: undefined, name: 'Puerto Rico', priority: 0},
  {iso: 'REU', iso2: undefined, name: 'Réunion', priority: 0},
  {iso: 'RWA', iso2: undefined, name: 'Ruanda', priority: 0},
  {iso: 'ROU', iso2: undefined, name: 'Rumänien', priority: 0},
  {iso: 'RUS', iso2: undefined, name: 'Russland', priority: 0},
  {iso: 'SLB', iso2: undefined, name: 'Salomonen', priority: 0},
  {iso: 'BLM', iso2: undefined, name: 'Saint-Barthélemy', priority: 0},
  {iso: 'MAF', iso2: undefined, name: 'Saint-Martin', priority: 0},
  {iso: 'ZMB', iso2: undefined, name: 'Sambia', priority: 0},
  {iso: 'WSM', iso2: undefined, name: 'Samoa', priority: 0},
  {iso: 'SMR', iso2: undefined, name: 'San Marino', priority: 0},
  {iso: 'STP', iso2: undefined, name: 'São Tomé und Príncipe', priority: 0},
  {iso: 'SAU', iso2: undefined, name: 'Saudi-Arabien', priority: 0},
  {iso: 'SWE', iso2: 'SE', name: 'Schweden', priority: 0},
  {iso: 'CHE', iso2: 'CH', name: 'Schweiz', priority: 1},
  {iso: 'SEN', iso2: undefined, name: 'Senegal', priority: 0},
  {iso: 'SRB', iso2: undefined, name: 'Serbien', priority: 0},
  {iso: 'SYC', iso2: undefined, name: 'Seychellen', priority: 0},
  {iso: 'SLE', iso2: undefined, name: 'Sierra Leone', priority: 0},
  {iso: 'ZWE', iso2: undefined, name: 'Simbabwe', priority: 0},
  {iso: 'SGP', iso2: undefined, name: 'Singapur', priority: 0},
  {iso: 'SXM', iso2: undefined, name: 'Sint Maarten', priority: 0},
  {iso: 'SVK', iso2: undefined, name: 'Slowakei', priority: 0},
  {iso: 'SVN', iso2: 'SI', name: 'Slowenien', priority: 0},
  {iso: 'SOM', iso2: undefined, name: 'Somalia', priority: 0},
  {iso: 'ESP', iso2: 'ES', name: 'Spanien', priority: 1},
  {iso: 'LKA', iso2: undefined, name: 'Sri Lanka', priority: 0},
  {iso: 'SHN', iso2: undefined, name: 'St. Helena, Ascension und Tristan da Cunha', priority: 0},
  {iso: 'KNA', iso2: undefined, name: 'St. Kitts und Nevis', priority: 0},
  {iso: 'LCA', iso2: undefined, name: 'St. Lucia', priority: 0},
  {iso: 'SPM', iso2: undefined, name: 'Saint-Pierre und Miquelon', priority: 0},
  {iso: 'VCT', iso2: undefined, name: 'St. Vincent und die Grenadinen', priority: 0},
  {iso: 'ZAF', iso2: undefined, name: 'Südafrika', priority: 0},
  {iso: 'SDN', iso2: undefined, name: 'Sudan', priority: 0},
  {iso: 'SGS', iso2: undefined, name: 'Südgeorgien und die Südlichen Sandwichinseln', priority: 0},
  {iso: 'SSD', iso2: undefined, name: 'Südsudan', priority: 0},
  {iso: 'SUR', iso2: undefined, name: 'Suriname', priority: 0},
  {iso: 'SJM', iso2: undefined, name: 'Spitzbergen und Jan Mayen', priority: 0},
  {iso: 'SYR', iso2: undefined, name: 'Syrien', priority: 0},
  {iso: 'TJK', iso2: undefined, name: 'Tadschikistan', priority: 0},
  {iso: 'TWN', iso2: undefined, name: 'Taiwan', priority: 0},
  {iso: 'TZA', iso2: undefined, name: 'Tansania', priority: 0},
  {iso: 'THA', iso2: undefined, name: 'Thailand', priority: 0},
  {iso: 'TGO', iso2: undefined, name: 'Togo', priority: 0},
  {iso: 'TKL', iso2: undefined, name: 'Tokelau', priority: 0},
  {iso: 'TON', iso2: undefined, name: 'Tonga', priority: 0},
  {iso: 'TTO', iso2: undefined, name: 'Trinidad und Tobago', priority: 0},
  {iso: 'TAA', iso2: undefined, name: 'Tristan da Cunha', priority: 0},
  {iso: 'TCD', iso2: undefined, name: 'Tschad', priority: 0},
  {iso: 'CZE', iso2: undefined, name: 'Tschechien', priority: 0},
  {iso: 'TUN', iso2: undefined, name: 'Tunesien', priority: 0},
  {iso: 'TUR', iso2: 'TR', name: 'Türkei', priority: 0},
  {iso: 'TKM', iso2: undefined, name: 'Turkmenistan', priority: 0},
  {iso: 'TCA', iso2: undefined, name: 'Turks- und Caicosinseln', priority: 0},
  {iso: 'TUV', iso2: undefined, name: 'Tuvalu', priority: 0},
  {iso: 'UGA', iso2: undefined, name: 'Uganda', priority: 0},
  {iso: 'UKR', iso2: 'UA', name: 'Ukraine', priority: 0},
  {iso: 'HUN', iso2: undefined, name: 'Ungarn', priority: 0},
  {iso: 'UMI', iso2: undefined, name: 'United States Minor Outlying Islands', priority: 0},
  {iso: 'URY', iso2: undefined, name: 'Uruguay', priority: 0},
  {iso: 'UZB', iso2: undefined, name: 'Usbekistan', priority: 0},
  {iso: 'VUT', iso2: undefined, name: 'Vanuatu', priority: 0},
  {iso: 'VAT', iso2: undefined, name: 'Vatikanstadt', priority: 0},
  {iso: 'VEN', iso2: undefined, name: 'Venezuela', priority: 0},
  {iso: 'ARE', iso2: undefined, name: 'Vereinigte Arabische Emirate', priority: 0},
  {iso: 'USA', iso2: 'US', name: 'Vereinigte Staaten', priority: 0},
  {iso: 'GBR', iso2: 'GB', name: 'Vereinigtes Königreich', priority: 0},
  {iso: 'VNM', iso2: undefined, name: 'Vietnam', priority: 0},
  {iso: 'WLF', iso2: undefined, name: 'Wallis und Futuna', priority: 0},
  {iso: 'CXR', iso2: undefined, name: 'Weihnachtsinsel', priority: 0},
  {iso: 'ESH', iso2: undefined, name: 'Westsahara', priority: 0},
  {iso: 'CAF', iso2: undefined, name: 'Zentralafrikanische Republik', priority: 0},
  {iso: 'CYP', iso2: undefined, name: 'Zypern', priority: 0}
];
