<!-- ---------------------------------------------------------------------------- -->
<ng-template #onlyMobilePost>
  <div *ngIf="getUserProfile() != null" class="d-block d-lg-none">
    <li class="nav-item" ngbDropdown>
      <a class="nav-link dropdown-toggle main-link" ngbDropdownToggle>
        Meine Daten
      </a>
      <div ngbDropdownMenu>
        <a
          class="dropdown-item ps-2 py-2"
          (click)="isNavbarCollapsed = true"
          [routerLink]="['/customer-dashboard']"
        >
          <span class="me-2"
          ><fa-icon
            class="me-3"
            [icon]="faHome"
            [fixedWidth]="true"
          ></fa-icon>
            {{ messages.overview }}
          </span>
        </a>
        <a
          *ngIf="config.isFeatureActive('EDUCATIONAL_STATE')"
          class="dropdown-item ps-2 py-2"
          (click)="isNavbarCollapsed = true"
          [routerLink]="['/customer-dashboard/mein-status']"
        >
          <span class="me-2">
            <fa-icon
              class="me-3"
              [icon]="faTasks"
              [fixedWidth]="true"
            ></fa-icon>
            {{ messages.educationalState }}
          </span>
        </a>
        <a
          class="dropdown-item ps-2 py-2"
          (click)="isNavbarCollapsed = true"
          [routerLink]="['/customer-dashboard/meine-kurse']"
        >
          <span class="me-2">
            <fa-icon
              class="me-3"
              [icon]="faCalendarAlt"
              [fixedWidth]="true"
            ></fa-icon>
            {{ messages.myCourses }}
          </span>
        </a>
        <a
          class="dropdown-item ps-2 py-2"
          (click)="isNavbarCollapsed = true"
          [routerLink]="['/customer-dashboard/favoriten']"
        >
          <span class="me-2"
          ><fa-icon
            class="me-3"
            [icon]="faStar"
            [fixedWidth]="true"
          ></fa-icon>
            {{ messages.searchFavorites }}
          </span>
        </a>
        <a
          class="dropdown-item ps-2 py-2"
          (click)="isNavbarCollapsed = true"
          [routerLink]="['/customer-dashboard/merkzettel']"
        >
          <span class="me-2">
            <fa-icon
              class="me-3"
              [icon]="faListAlt"
              [fixedWidth]="true"
            ></fa-icon>
            {{ messages.bookmarkList }}
          </span>
        </a>
        <a
          class="dropdown-item ps-2 py-2"
          (click)="isNavbarCollapsed = true"
          [routerLink]="['/customer-dashboard/meine-daten']"
        >
          <span class="me-2">
            <fa-icon
              class="me-3"
              [icon]="faAddressCard"
              [fixedWidth]="true"
            ></fa-icon>
            {{ messages.myData }}
          </span>
        </a>
      </div>
    </li>
    <li class="nav-item">
      <a
        class="btn w-100 fw-bold btn-outline-secondary my-2 p-2"
        (click)="logoutUser()"
        (keydown)="logoutUser($event)"
      >
        <span class="me-2"> Abmelden </span>
      </a>
    </li>
  </div>
  <div *ngIf="getUserProfile() == null" class="d-block d-lg-none">
    <li class="nav-item">
      <button
        class="btn w-100 fw-bold btn-outline-secondary my-2 p-2"
        (click)="getAuthUrl(); isNavbarCollapsed = true"
      >
        <span class="me-2"> Anmelden </span>
      </button>
    </li>
    <li class="nav-item">
      <button
        class="btn w-100 fw-bold btn-outline-secondary my-2 p-2"
        (click)="getRegisterUrl()"
      >
        <span class="me-2"> Registrieren </span>
      </button>
    </li>
  </div>
</ng-template>
<ng-template #menuTemp let-menue="menue">
  <li
    class="nav-item d-inline-block"
    ngbDropdown
    autoClose="outside"
    #mainDrop="ngbDropdown"
  >
    <a
      *ngIf="!menue.sections && !menue.external"
      class="nav-link main-link"
      [routerLink]="menue.url"
      (click)="mainDrop.close()"
    >
      {{ menue.name }}
    </a>
    <a
      *ngIf="!menue.sections && menue.external"
      class="nav-link main-link"
      href="{{ menue.url }}"
    >
      {{ menue.name }}
    </a>
    <button
      *ngIf="menue.sections"
      class="btn btn-link nav-link dropdown-toggle main-link"
      ngbDropdownToggle
    >
      {{ menue.name }}
    </button>
    <div *ngIf="menue.sections" ngbDropdownMenu class="multi-level">
      <ng-container *ngFor="let entry of menue.sections">
        <div *ngIf="entry.head" class="d-flex justify-content-between">
          <a
            class="dropdown-item sub-link font-size-small pt-1 pb-1"
            [routerLink]="'/' + entry.head.url"
            (click)="onClickCategory(); mainDrop.close()"
          ><strong>{{ entry.head.name }}</strong></a
          >
        </div>
        <div class="menu-flex me-3">
          <div class="col pe-0">
            <ng-container *ngFor="let subMenu of entry.entries; let i = index">
              <ng-container *ngIf="i < entry.entries.length / 2">
                <ng-container
                  *ngTemplateOutlet="
                    subMenuTemp;
                    context: { submenu: subMenu, mainDrop: mainDrop }
                  "
                ></ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div class="col pe-0">
            <ng-container *ngFor="let subMenu of entry.entries; let i = index">
              <ng-container *ngIf="i >= entry.entries.length / 2">
                <ng-container
                  *ngTemplateOutlet="
                    subMenuTemp;
                    context: { submenu: subMenu, mainDrop: mainDrop }
                  "
                ></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </li>
</ng-template>

<ng-template #subMenuTemp let-submenu="submenu" let-mainDrop="mainDrop">
  <a
    class="dropdown-item sub-link font-size-small pt-1 pb-1"
    *ngIf="!submenu.sections && !submenu.external"
    [routerLink]="submenu.url"
    (click)="onClickCategory(); mainDrop.close()"
  >{{ submenu.name }}</a
  >
  <a
    class="dropdown-item sub-link font-size-small pt-1 pb-1"
    *ngIf="!submenu.sections && submenu.external"
    href="{{ submenu.url }}"
    (click)="mainDrop.close()"
  >{{ submenu.name }}</a
  >
  <ng-container *ngIf="submenu.sections">
    <div class="dropdown-submenu" ngbDropdown autoClose="outside">
      <a
        class="dropdown-item sub-link font-size-small pt-1 pb-1"
        ngbDropdownToggle
      >{{ submenu.name }}</a
      >
      <div ngbDropdownMenu class="dropdown-menu">
        <ng-container *ngFor="let entry of submenu.sections">
          <div class="d-flex justify-content-between">
            <a
              *ngIf="entry.head"
              class="dropdown-item sub-link font-size-small pt-1 pb-1"
              [routerLink]="'/' + entry.head.url"
              (click)="onClickCategory(); mainDrop.close()"
            ><strong>{{ entry.head.name }}</strong></a
            >
          </div>
          <div class="menu-flex">
            <div class="col-12">
              <ng-container
                *ngFor="let subMenu of entry.entries; let i = index"
              >
                <ng-container
                  *ngTemplateOutlet="
                    subMenuTemp;
                    context: { submenu: subMenu, mainDrop: mainDrop }
                  "
                ></ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
<!-- ---------------------------------------------------------------------------- -->

<button
  class="navbar-toggler"
  type="button"
  (click)="isNavbarCollapsed = !isNavbarCollapsed"
  data-target="#navigation"
  aria-controls="navigation"
  aria-expanded="false"
  aria-label="Toggle navigation"
>
  <span class="navbar-toggler-icon"></span>
</button>

<div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse navigation-menu" [id]="mobile ? 'navigation-mobile' : 'navigation'">
  <div id="gray-overlay" (click)="isNavbarCollapsed = true" (keydown)="isNavbarCollapsed = true"></div>
  <div id="navigation-block">
    <ul class="navbar-nav">
      <ng-container *ngFor="let menu of messages.menues">
        <ng-container
          *ngTemplateOutlet="menuTemp; context: { menue: menu }"
        ></ng-container>
      </ng-container>

      <!-- ONLY-MOBILE: -->
      <!-- ------------------------------------------------------------ -->
      <ng-container *ngTemplateOutlet="onlyMobilePost"></ng-container>
      <!-- ------------------------------------------------------------ -->
    </ul>
  </div>
</div>
