import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private shown = false;

  constructor() {}

  public show() {
    this.shown = true;
  }

  public hide() {
    this.shown = false;
  }

  public isShown() {
    return this.shown;
  }
}
