import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appActivetoggle]'
})
export class ActivetoggleDirective {
  constructor(private el: ElementRef) {}

  @HostListener('click')
  onclick() {
    const articles = Array.from(
      window.document.querySelectorAll(
        '.list-group-item.list-group-item-action'
      )
    );

    for (let i = 0; i < articles.length; i++) {
      articles[i].classList.remove('activated');
    }

    const classesActive = this.el.nativeElement.classList;
    classesActive.add('activated');
  }
}
