import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  faAddressCard,
  faCalendarAlt,
  faListAlt,
  faStar,
  faUser
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faHome,
  faShoppingCart,
  faTasks
} from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  throttleTime
} from 'rxjs/operators';
import { CartService } from '../../cart.service';
import { ConfigService } from '../../config.service';
import { MessageService } from '../../message-service';
import { MessageJson } from '../../models/message-json';
import { AffiliateService } from '../../services/affiliate.service';
import { Api } from '../../services/api.service';
import { UserService } from '../../user/user.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './navigation/navigation.component';
import {AppModule} from "../../app.module";
import {ExternalLinkComponent} from "../external-link/external-link.component";

enum Direction {
  Up = 'Up',
  Down = 'Down'
}

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgOptimizedImage,
    NgbDropdownModule,
    NavigationComponent,
    ExternalLinkComponent
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  faListAlt = faListAlt;
  faStar = faStar;
  faHome = faHome;
  faTasks = faTasks;
  faCalendarAlt = faCalendarAlt;
  faUser = faUser;
  faAddressCard = faAddressCard;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faShoppingCart = faShoppingCart;

  messages: MessageJson;
  menuOpen = false;
  scrolled: boolean;

  constructor(
    private messageService: MessageService,
    private keycloakService: KeycloakService,
    private router: Router,
    private userService: UserService,
    public config: ConfigService,
    private cartService: CartService,
    private affiliateService: AffiliateService,
    private api: Api
  ) {}

  ngOnInit() {
    this.messages = this.messageService.getMessages();
    this.loadKeycloakData();
    this.scrolled = false;
    this.cartService.update();
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => {
        if (y2 - y1 > 10) {
          return Direction.Down;
        }
        if (y2 - y1 < -10 || y2 === 0) {
          return Direction.Up;
        }
      }),
      distinctUntilChanged(),
      share()
    );

    const goingUp$ = scroll$.pipe(
      filter((direction) => direction === Direction.Up)
    );

    const goingDown$ = scroll$.pipe(
      filter((direction) => direction === Direction.Down)
    );

    goingUp$.subscribe(() => (this.scrolled = false));
    goingDown$.subscribe(() => (this.scrolled = true));
  }

  private loadKeycloakData() {
      if (this.keycloakService.isLoggedIn()) {
        this.keycloakService.loadUserProfile(true).then((profile) => {
          this.userService.setUserProfile(profile);
        });
        this.userService.setLoginCookie();
        if (
          window.location.pathname.indexOf('/check') < 0 &&
          window.location.pathname.indexOf('/customer-dashboard') < 0
        ) {
          this.api.validateCart().subscribe((response) => {
            this.cartService.setCart(response.cart);
            localStorage.removeItem('cart');
          });
        }
      }
  }

  public logoutUser($event?: KeyboardEvent) {
    if ($event && $event.code !== 'Enter') {
      return;
    }
    this.userService.removeCookies();
    this.keycloakService.logout(this.config.getDomain());
    this.keycloakService.clearToken();
    localStorage.removeItem('cp');
  }

  public toogleMenu($event?: KeyboardEvent) {
    if ($event && $event.code !== 'Enter') {
      return;
    }
    this.menuOpen = !this.menuOpen;
  }

  public getUserProfile(): KeycloakProfile {
    return this.userService.getUserProfile();
  }

  public getAuthUrl() {
    const stateObj = this.router.url;
    history.pushState(stateObj, document.title, this.router.url);
    let redirectUri = this.config.getDomain() + '/customer-dashboard';
    if (this.affiliateService.getAffiliate()) {
      redirectUri += '?aid=' + this.affiliateService.getAffiliate();
    }
    this.keycloakService.login({
      redirectUri: redirectUri
    });
  }

  public getRegisterUrl() {
    const stateObj = this.router.url;
    history.pushState(stateObj, document.title, this.router.url);
    this.keycloakService.register({
      redirectUri: this.config.getDomain() + '/customer-dashboard'
    });
  }

  showCart() {
    return this.cartService.getCartAmount() !== 0;
  }

  getCartAmount(): number {
    return this.cartService.getCartAmount();
  }

  getLogo(): string {
    return this.config.getLogo();
  }

  isBookmarkActive() {
    return this.config.isFeatureActive('BOOKMARK');
  }
}
