import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  initPromotion() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const pc = this.route.snapshot.queryParamMap.get('pc');
        if (pc) {
          this.cookieService.set(
            'pc',
            pc,
            0,
            '/',
            document.location.hostname,
            true,
            'Strict'
          );
        }
      });
  }
}
