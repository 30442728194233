const excludePath = [
  '/messages',
  '/search',
  '/course',
  '/scope',
  '/contact',
  '/faq',
  '/api/newsletter$',
  '/content',
  '/organizer',
  '/blog'
];

export const environmentCommon = {
  excludePath: excludePath
};
