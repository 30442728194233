import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../config.service';
import { OrganizerService } from '../services/organizer.service';

@Injectable({
  providedIn: 'root'
})
export class CoursePreviewOrganizerResolverService {
  constructor(
    public organizerService: OrganizerService,
    public config: ConfigService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (
      this.config
        .getStartpageModules()
        .findIndex((value) => value.name === 'COURSE_PREVIEW') > -1
    ) {
      return this.organizerService.getOrganizers();
    }
    return of({});
  }
}
