import {Inject, Injectable} from "@angular/core";
import {JsonLd} from "../models/json-ld";
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class JsonLdService {
    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    setData(jsonLD: JsonLd[]) {
        const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        let jsonLdTag;
        let elements = this.document.getElementsByTagName('script');
        for (let i = 0; i < elements.length; i++) {
            const scriptTag = elements.item(i);
            if (scriptTag.getAttribute('type') === 'application/ld+json') {
                jsonLdTag = scriptTag;
            }
        }
        if (!jsonLdTag) {
            jsonLdTag = this.document.createElement('script');
            jsonLdTag.setAttribute('type', 'application/ld+json')
        }
        jsonLdTag.text = json;
        document.body.appendChild(jsonLdTag);
    }
}
