import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  faAddressCard,
  faCalendarAlt,
  faListAlt,
  faStar
} from '@fortawesome/free-regular-svg-icons';
import { faHome, faTasks } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { ConfigService } from '../../../config.service';
import { MessageService } from '../../../message-service';
import { MessageJson } from '../../../models/message-json';
import { SearchService } from '../../../search/search/search.service';
import { AffiliateService } from '../../../services/affiliate.service';
import { UserService } from '../../../user/user.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NgbCollapseModule,
  NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    NgbDropdownModule,
    NgbCollapseModule
  ]
})
export class NavigationComponent implements OnInit {
  @Input()
  mobile = false;

  isNavbarCollapsed = true;
  messages: MessageJson;

  faListAlt = faListAlt;
  faStar = faStar;
  faHome = faHome;
  faTasks = faTasks;
  faCalendarAlt = faCalendarAlt;
  faAddressCard = faAddressCard;

  constructor(
    private messageService: MessageService,
    private keycloakService: KeycloakService,
    private userService: UserService,
    private affiliateService: AffiliateService,
    private router: Router,
    private config: ConfigService
  ) {}

  ngOnInit() {
    this.messages = this.messageService.getMessages();
    this.loadKeycloakData();
  }

  private loadKeycloakData() {
      if (this.keycloakService.isLoggedIn()) {
        this.keycloakService.loadUserProfile(true).then((profile) => {
          this.userService.setUserProfile(profile);
        });
        this.userService.setLoginCookie();
      }
  }

  public logoutUser($event?: KeyboardEvent) {
    if ($event && $event.code !== 'Enter') {
      return;
    }
    this.userService.removeCookies();
    this.keycloakService.logout(this.config.getDomain());
    this.keycloakService.clearToken();
    localStorage.removeItem('cp');
  }

  public getUserProfile(): KeycloakProfile {
    return this.userService.getUserProfile();
  }

  public getAuthUrl() {
    const stateObj = this.router.url;
    history.pushState(stateObj, document.title, this.router.url);
    let redirectUri = this.config.getDomain() + '/customer-dashboard';
    if (this.affiliateService.getAffiliate()) {
      redirectUri += '?aid=' + this.affiliateService.getAffiliate();
    }
    this.keycloakService.login({
      redirectUri: redirectUri
    });
  }

  public getRegisterUrl() {
    const stateObj = this.router.url;
    history.pushState(stateObj, document.title, this.router.url);
    this.keycloakService.register({
      redirectUri: this.config.getDomain() + '/customer-dashboard'
    });
  }

  public onClickCategory() {
    SearchService.clearSearchData();
    this.isNavbarCollapsed = true;
  }
}
