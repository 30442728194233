import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { CookieService } from 'ngx-cookie-service';
import { CustomerJson } from '../models/customer-json';
import { ConfigService } from '../config.service';
import { Api } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private token = '';
  private refreshToken = '';
  private _user: CustomerJson;
  private userName = '';
  private userProfile: KeycloakProfile;
  private loggedIn = false;

  constructor(
    private keycloak: KeycloakService,
    private configurationService: ConfigService,
    private api: Api,
    private cookieService: CookieService
  ) {
    this.loadTokenFromStorage();
  }

  public get user() {
    return this._user;
  }

  public set user(user: CustomerJson) {
    this._user = user;
  }

  private loadTokenFromStorage() {
    this.token = localStorage.getItem('rf_token') || '';
    this.refreshToken = localStorage.getItem('rf_refreshToken') || '';
  }

  public setLoginCookie() {
    this.cookieService.set(
      'rfLoggedIn',
      'true',
      0,
      '/',
      document.location.hostname,
      true,
      'Strict'
    );
    this.loggedIn = true;
  }

  public removeCookies() {
    this.userProfile = null;
    this.cookieService.delete('rfLoggedIn');
    localStorage.removeItem('cart');
  }

  public getUserProfile(): KeycloakProfile {
    return this.userProfile;
  }

  public setUserProfile(value: KeycloakProfile) {
    this.userProfile = value;
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }
}
