import {Component, OnInit} from '@angular/core';
import { RouterLink } from '@angular/router';
import {ConfigService} from "../../config.service";
import {MessageService} from "../../message-service";
import {MessageJson} from "../../models/message-json";
import {ExternalLinkComponent} from "../../page-elements/external-link/external-link.component";

@Component({
  standalone: true,
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  imports: [RouterLink, ExternalLinkComponent],
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  protected messages: MessageJson;
  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.messages = this.messageService.getMessages();
  }
}
