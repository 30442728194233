import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {}

  public static toRequestFormat(oldDateObject: NgbDate): string {
    let newDateString: string;
    const separator = '-';

    newDateString = oldDateObject.year.toString() + separator;

    if (oldDateObject.month < 10) {
      newDateString = newDateString + '0' + oldDateObject.month.toString();
    } else {
      newDateString = newDateString + oldDateObject.month.toString();
    }

    newDateString = newDateString + separator;

    if (oldDateObject.day < 10) {
      newDateString = newDateString + '0' + oldDateObject.day.toString();
    } else {
      newDateString = newDateString + oldDateObject.day.toString();
    }

    return newDateString;
  }

  public static toDate(value: string): NgbDate {
    if (value) {
      const splittedValue = value.split('-');
      return new NgbDate(
        Number(splittedValue[0]),
        Number(splittedValue[1]),
        Number(splittedValue[2])
      );
    }
    return null;
  }

  static formatDate(pattern: string, date: string) {
    const datePipe = new DatePipe(navigator.language);
    if (date !== undefined && date !== '' && date != null) {
      return datePipe.transform(date, pattern);
    }
    return null;
  }

  public static getIdFromPath(name: string): number {
    const seperatorIndex: number = name.lastIndexOf('-');
    return Number(name.substring(seperatorIndex + 1));
  }

  public static getScopeName(name: string): string {
    const seperatorIndex: number = name.lastIndexOf('-');
    return name.substr(0, 1).toUpperCase() + name.substring(1, seperatorIndex);
  }

  static convertDate(value: string): Date {
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      // '01.01.2000 15:33 Uhr'
      const withoutClock = value.substr(0, 16);
      const parts = withoutClock.split(' ');
      const str = parts[0].split('.');

      const day = Number(str[0]);
      const month = Number(str[1]) - 1;
      const year = Number(str[2]);

      if (parts[1]) {
        const time = parts[1].split(':');

        const hours = Number(time[0]);
        const minutes = Number(time[1]);
        return new Date(year, month, day, hours, minutes);
      } else {
        return new Date(year, month, day);
      }
    }
    return null;
  }
}
