import { CourseOverviewJson } from './course-overview-json';

export class SearchResponseJson {
  count: number;
  courseTypes: Array<string>;
  courses: Array<CourseOverviewJson>;
  minPrice: number;
  maxPrice: number;
  minDuration: number;
  maxDuration: number;
  priceSteps: number;
  durationSteps: number;
}
