import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizerInfoResponse } from '../models/organizer-info-response';
import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizerService {
  private organizers: Array<OrganizerInfoResponse> = null;
  constructor(private api: Api) {}

  public getOrganizers(): Observable<OrganizerInfoResponse[]> {
    if (this.organizers) {
      return of(this.organizers);
    }
    return this.api.getOrganizers().pipe(map((o) => (this.organizers = o)));
  }

  public getOrganizersList(): OrganizerInfoResponse[] {
    return this.organizers;
  }
}
