<div
  class="container col-12 m-0 p-0"
  [ngClass]="{ 'bg-dark': style !== 'light' }"
>
  <div
    class="col-12 text-primary bg-secondary pe-0"
    [ngClass]="{ 'bg-dark': style !== 'light' }"
  >
    <h3
      [ngClass]="{
        headline2: style !== 'light',
        'with-header headline4 text-dark': style === 'light'
      }"
    >
      Newsletteranmeldung<span *ngIf="style === 'light'" class="header"></span>
    </h3>
    <div *ngIf="!send">
      <form
        (ngSubmit)="formSubmit()"
        #newsletterForm="ngForm"
        class="form-inline"
      >
        <div class="col-12 row m-0 p-0">
          <input
            name="email"
            placeholder="E-Mail-Adresse"
            type="text"
            class="form-control col-10 textfield-attached email-field"
            [(ngModel)]="data.email"
            aria-label="eMail"
            autocomplete="email"
            email
          />
          <button
            type="submit"
            aria-label="Absenden"
            class="btn button-newsletter btn-primary button-attached btn-lg float-end col-2"
          >
            <fa-icon [icon]="faEnvelope" class="button-icon"></fa-icon>
          </button>
        </div>
      </form>
      <div class="legal-advice">
        <p>
          Ja, ich möchte den kostenlosen Newsletter von {{ name() }} abonnieren.
          Die
          <a
            [ngClass]="{
              'text-white': style !== 'light',
              'text-dark': style === 'light'
            }"
            [routerLink]="['/datenschutz/']"
            >Datenschutzhinweise</a
          >
          habe ich gelesen. Meine Einwilligung kann ich jederzeit durch
          Abbestellung des Newsletters widerrufen. Ein Abmeldelink ist am Ende
          jedes Newsletters enthalten.
        </p>
      </div>
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="validationErrors.size > 0"
      >
        <div *ngFor="let error of validationErrors | keyvalue">
          {{ error.value }}
        </div>
        <button type="button" class="close" (click)="validationErrors.clear()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div *ngIf="send">
      <h3 class="headline3">Vielen Dank für Ihre Anmeldung!</h3>
      <p>Sie erhalten in K&uuml;rze eine Best&auml;tigungs-EMail.</p>
    </div>
  </div>
</div>
