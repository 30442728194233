import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../config.service';
import { Api } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CoursePreviewResolver {
  constructor(private api: Api, public config: ConfigService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (
      this.config
        .getStartpageModules()
        .findIndex((value) => value.name === 'COURSE_PREVIEW') > -1
    ) {
      return this.api.getSearch({ limit: 12, start: 1 });
    }
    return of({});
  }
}
