import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ConfigService } from '../config.service';
import { AffiliateService } from '../services/affiliate.service';

@Injectable({
  providedIn: 'root'
})
export class KeycloakGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloak: KeycloakService,
    private config: ConfigService,
    private affiliateService: AffiliateService
  ) {
    super(router, keycloak);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.authenticated) {
        let redirectUri = this.config.getDomain() + state.url;
        if (this.affiliateService.getAffiliate()) {
          redirectUri += '?aid=' + this.affiliateService.getAffiliate();
        }
        this.keycloak.login({
          redirectUri: redirectUri
        });
        reject(true);
        return;
      }
      resolve(true);
    });
  }
}
